import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { Sidebar } from "./Sidebar";
import { useParams } from "react-router-dom";
import axios from "axios";
import { COMPETITIONS_URL } from "../../../utils/endpoint";
import NcImage from "components/NcImage/IPINcImage";
import HtmlParser from "react-html-parser";

export interface PageSingleTemp3SidebarProps {
  className?: string;
}

const CompetitionDetail: FC<PageSingleTemp3SidebarProps> = ({
  className = "",
}) => {
  const params: any = useParams();

  const [event, setEvent]: any = useState([]);
  const [loading, setLoading]: any = useState(false);

  const start = Date.now();
  var dateEvent = moment(event.date);
  var isValidEvent = dateEvent.isAfter(start);

  useEffect(() => {
    axios
      .get(`${COMPETITIONS_URL}${params.id}`)
      .then((res: any) => {
        setEvent(res.data);
        setLoading(true);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
  }, []);

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <>
      <div
        className={`nc-PageSingleTemp3Sidebar ${className}`}
        data-nc-id="PageSingleTemp3Sidebar"
      >
        <NcImage
          containerClassName="container my-10 sm:my-12"
          className="object-cover w-full h-full rounded-xl"
          src={event.image}
        />

        <div className="container rounded-xl">
          {/* <Helmet>
        <title>Kompetisi - {event.name}</title>
      </Helmet> */}
          <div className={`nc-SingleHeader`}>
            <div className="space-y-5">
              <h1
                className={
                  className +
                  "text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-5xl dark:text-neutral-100 max-w-4xl "
                }
                title={event.title}
              >
                {event.title}
              </h1>
              <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
              <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5"></div>
            </div>
          </div>
        </div>
        <div className="container flex flex-col my-10 lg:flex-row ">
          {loading && (
            <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-20">
              <div className="nc-SingleContent space-y-10">
                <div
                  id="single-entry-content"
                  className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
                >
                  <div>{HtmlParser(event.description)}</div>
                </div>
              </div>
            </div>
          )}
          <div className="w-full mt-12 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3">
            {!isValidEvent ? <Sidebar /> : "Lomba ini telah ditutup"}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompetitionDetail;
