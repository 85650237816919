import Heading from "components/Heading/IPIHeading";
import NcImage from "components/NcImage/IPINcImage";
import Mutiara from "./Images/MUTIARA.jpg"
import Yohanna from "./Images/YOHANNA.jpg"
import ATISATYA from "./Images/ATISATYA.jpg"
import FARIDANINGSIH from "./Images/FARIDA.jpg"
import SARI from "./Images/SARI.jpg"
import SILVIANTI from "./Images/SILVIANTI.png"
import HANY from "./Images/HANY.jpg"

const FOUNDER_DEMO: any = [
  {
    id: "1",
    name: `Mutiara H. M., B. BA, M.BA`,
    job: "Founder",
    avatar:
    Mutiara,
  },
  {
    id: "2",
    name: `Silvianti Dewi, ST`,
    job: "CEO - Co Founder",
    avatar:
    SILVIANTI,
  },
  {
    id: "3",
    name: `Yohanna Islamidienna, S.Ikom`,
    job: "CMO - Co Founder",
    avatar:
      Yohanna,
  },
  {
    id: "4",
    name: `Farida Ningsih`,
    job: " Co Founder",
    avatar:
    FARIDANINGSIH,
  },
  {
    id: "5",
    name: `Sari Kurnia`,
    job: "Co Founder",
    avatar:
    SARI,
  },
  {
    id: "6",
    name: `Atisatya Arifin`,
    job: "Co Founder ",
    avatar:
    ATISATYA,
  },
  {
    id: "7",
    name: `Hany Ricardo`,
    job: "Co Founder ",
    avatar:
    HANY,
  },
];

const SectionFounder = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading
        desc=""
      >
        ⛱ Founder
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item: any) => (
          <div key={item.id} className="max-w-sm">
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover"
              src={item.avatar}
            />
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.job}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFounder;
