import React, { FC, useEffect, useState } from "react";
import WidgetAuthors from "components/WidgetAuthors/WidgetAuthors";
import WidgetPosts from "components/WidgetPosts/WidgetPosts";
import WidgetTags from "components/WidgetTags/WidgetTags";
import {POSTCATEGORY_URL, POST_URL, POST_AUTHOR} from "../../../utils/endpoint"
import axios from "axios";
export interface SidebarProps {
  className?: string;
}

export const Sidebar: FC<SidebarProps> = ({ className = "space-y-6 " }) => {
  const [category, setCategory]: any = useState([]);
  const [loading, setLoading]: any = useState(false);

  const [post, setPost]: any = useState([]);
  const [loadingPost, setLoadingPost]: any = useState(false);

  const [postAuthor, setPostAuthor]: any = useState([]);
  const [loadingAuthor, setLoadingAuthor]: any = useState(false);
  useEffect(() => {
    axios
      .get(POSTCATEGORY_URL)
      .then((res : any) => {
          setCategory(res.data);
          setLoading(true);
      })
      .catch((error : any) => {
        console.log(error.response.data);
      });

      axios
      .get(POST_URL)
      .then((res : any) => {
          setPost(res.data);
          setLoadingPost(true);
      })
      .catch((error : any) => {
        console.log(error.response.data);
      });

      axios
      .get(POST_AUTHOR)
      .then((res : any) => {
          setPostAuthor(res.data);
          setLoadingAuthor(true);
      })
      .catch((error : any) => {
        console.log(error.response.data);
      });
  }, []);
  return (
    <div className={`nc-SingleSidebar ${className}`}>
      {
        loadingPost && 
        <WidgetPosts posts={post} />
      }
      {
        loading && 
        <WidgetTags tags={category} />
      }
      {/* {
        loadingPost1 && 
        <WidgetCategories categories={postCategory1} />
      } */}
      {loadingAuthor &&
        <WidgetAuthors authors={postAuthor} />
      }
    </div>
  );
};
