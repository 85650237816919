import rightImg from "images/about-hero-right.png";
import React, { FC, useEffect } from "react";
import SectionFounder from "./SectionFounder";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/IPISectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/IPIBgGlassmorphism";
import SectionHeroSejarah from "containers/PageAbout/SectionHeroHistory";
import SectionHeroVisiMisi from "containers/PageAbout/SectionHeroVisiMisi";
import SectionPengurus from "./SectionPengurus";
import SectionHeroTentangIPI from "./SectionHeroAboutIPI";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className} `}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Tentang IPI</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHeroTentangIPI
          rightImg={rightImg}
          heading="Tentang IPI"
          btnText=""
          subHeading=""
        />

        <SectionHeroSejarah
          rightImg={rightImg}
          heading="Sejarah"
          btnText=""
          subHeading=""
        />

        <SectionHeroVisiMisi
          rightImg={rightImg}
          heading="Visi Misi"
          btnText=""
          subHeading=""
        />
        <SectionFounder />
        <SectionPengurus />
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageAbout;
