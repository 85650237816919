import React, {useState, useEffect} from "react";
import ButtonPrimary from "components/Button/IPIButtonPrimary";
import Input from "components/Input/IPIInput";
import Label from "components/Label/Label";
import { LOCALSTORAGE_USERDETAIL, LOCALSTORAGE_TOKEN } from '../../utils/Types';
import { AUTH_URL_USERME } from '../../utils/endpoint';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Spinner, Alert } from "reactstrap";
import Textarea from "components/Textarea/Textarea";

const DashboardEditProfile = () => {
  const [loading, setLoading] = useState(false)
  const [isAlertOn, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const history = useHistory();

  //  initialvalue
  const [email, setEmail] : any =  useState('')
  const [fullname, setFullname] : any = useState('')
  const [phone, setPhone] : any = useState('')
  const [address, setAddress] : any = useState('')
  const [photo, setPhoto] : any = useState('')
  const [loadingUser, setLoadingUser] : any = useState(false);

  useEffect(() => {
    const localdata : any = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL) || '{}');
    setEmail(localdata.email)
    setFullname(localdata.fullname);
    setPhone(localdata.phone)
    setAddress(localdata.address)
    setPhoto(localdata.photo)
    setLoadingUser(true);
  }, []);

  const errorHandling = (msg : any) => {
    setErrorMsg(` ${JSON.stringify(msg)}`);
    setLoading(false);
    setAlert(true);
    setTimeout(()=> {setAlert(false);}, 10000)
  };

  const handleSubmit = (e : any) => {
    e.preventDefault();
    const data = new FormData();
    data.append('fullname', e.target.fullname.value);
    data.append('phone',  e.target.phone.value);
    data.append('address', e.target.address.value);
    if ([e.target.photo][0].files[0]) {
      data.append('photo', [e.target.photo][0].files[0]);
    }

    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
    },
    };

    axios.patch(AUTH_URL_USERME, data, options)
      .then((userData:any) => {
        errorHandling('posting data berhasil')
        localStorage.setItem(LOCALSTORAGE_USERDETAIL, JSON.stringify(userData.data));
        history.push({
          pathname: '/dashboard/edit-profile',
        });
        window.location.reload();
        setLoading(false);
      })
      .catch((error) => {
        errorHandling(error.response.data)
        setLoading(false);
      }).catch((error) => {
        errorHandling(error.response.data)
      });
  }

  return (
    <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
      <img src={loadingUser && photo} alt="" width="100" height="100" />
      <br />
      {isAlertOn && <Alert onClick={()=>setAlert(false) } color="danger">{ errorMsg}</Alert>}
      <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>



        <label className="block md:col-span-2">
          <Label>Nama</Label>
          <Input
            type="text"
            name="fullname"
            value={fullname}
            onChange={(e) => {
              setFullname(e.target.value)
            }}
          />
        </label>

        <label className="block">
          <Label>No.Handphone (WA)</Label>
          <Input
            name="phone"
            type="number"
            className="mt-1"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value)
            }}
          />
        </label>
        <label className="block">
          <Label>Email</Label>
          <Input
            type="email"
            name="email"
            value={email}
            disabled
          />
        </label>

        <label className="block md:col-span-2">
          <Label>Alamat</Label>
          <Textarea
            name="address"
            className="mt-1"
            rows={6}
            value={address}
            onChange={(e) => {
              setAddress(e.target.value)
            }}
          />
        </label>

        <label className="block">
          <Label>Foto Profil</Label>
          <input
            type="file"
            name="photo"
            accept="image/png, image/jpeg"
          />
        </label>

        {loading ? <Spinner color="success" /> : ''}
        <ButtonPrimary className="md:col-span-2" type="submit">
          Simpan
        </ButtonPrimary>
      </form>
    </div>
  );
};

export default DashboardEditProfile;
