import React, { useEffect, useState } from "react";
import Input from "components/Input/IPIInput";
import Label from "components/Label/Label";
import ButtonPrimary from "components/Button/IPIButtonPrimary";
import Select from "components/Select/Select";
// import Textarea from "components/Textarea/Textarea";
import axios from "axios";
import { Alert, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { LOCALSTORAGE_USERDETAIL, LOCALSTORAGE_TOKEN } from "../../../utils/Types";
import { POST_URL, POSTCATEGORY2_URL } from "../../../utils/endpoint";
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState } from 'draft-js';
// import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import { Editor } from "@tinymce/tinymce-react";
import LayoutPage from "components/LayoutPage/IPILayoutPage";
import { Helmet } from "react-helmet";


const DashboardSubmitPost = () => {
  const [category2, setCategory2]: any = useState([]);
  const [loadingCategory, setLoadingCategory]: any = useState(false);

  useEffect(() => {
    axios
      .get(POSTCATEGORY2_URL)
      .then((res: any) => {
        setCategory2(res.data);
        setLoadingCategory(true);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
  }, []);

  const [myUser, setMyUser]: any = useState(null);
  const [loadingUser, setLoadingUser]: any = useState(false);
  useEffect(() => {
    const localdata: any = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL) || "{}");
    setMyUser(localdata);
    setLoadingUser(true);
  }, []);

  const [loading, setLoading] = useState(false);
  const [isAlertOn, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const history = useHistory();

  const errorHandling = (msg: any) => {
    setErrorMsg(` ${JSON.stringify(msg)}`);
    setLoading(false);
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 10000);
  };

  const [content, setContents] = useState("");
  const handleEditorChange = (contents: any, editor: any) => {

    setContents(contents);
  };


  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = new FormData();
    data.append("author_id", e.target.author_id.value);
    data.append("title", e.target.title.value);
    data.append("subcategory_2_id", e.target.subcategory_2_id.value);
    if (e.target.image.files[0]) {
      data.append("image", e.target.image.files[0]);
    }
    data.append("content", content);
    data.append("is_publish", "false"); // user biasa setelah post tidak langsung di publish
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };

    axios
      .post(POST_URL, data, options)
      .then((error) => {
        // const userdetail = userData.data;
        errorHandling("posting data berhasil");
        // localStorage.setItem(LOCALSTORAGE_USERDETAIL, JSON.stringify(userData.data));
        // window.location.reload();
        history.push({
          pathname: "/dashboard/posts",
          // state: { data: listingAttendance },
        });
        setLoading(false);
      })
      .catch((error) => {
        errorHandling(error.response.data);
        setLoading(false);
      })
      .catch((error) => {
        errorHandling(error.response.data);
      });
  };
  return (
    <div className={`nc-PageDashboard theme-color-ipi`} data-nc-id="PageDashboard">
      <Helmet>
        <title>Artikel | Tambah</title>
      </Helmet>
      <LayoutPage
        // subHeading="View your dashboard, manage your Posts, Subscription, edit password and profile"
        headingEmoji="➕"
        heading={`Tambah Artikel`}
        // heading={`Dashboard ${loading && myUser.fullname}`}
      >
        <div className=" py-3">
          <ButtonPrimary href="/dashboard/posts">👈 Kembali</ButtonPrimary>
        </div>
        <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
          {isAlertOn && (
            <Alert onClick={() => setAlert(false)} color="danger">
              {errorMsg}
            </Alert>
          )}

          <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
            <input name="author_id" type="hidden" value={loadingUser && myUser.id} />
            <label className="block md:col-span-2">
              <Label>Judul Artikel*</Label>
              <Input type="text" name="title" className="mt-1" />
            </label>

            <label className="block  md:col-span-2">
              <Label>Kategori</Label>
              <Select className="mt-1" name="subcategory_2_id">
                <option selected disabled>
                  Pilih Salah Satu
                </option>
                {loadingCategory &&
                  category2.map((data: any) => (
                    <option key={data.id} value={data.id}>
                      {data.name}
                    </option>
                  ))}
              </Select>
            </label>

            <div className="block md:col-span-2">
              <Label>Featured Image</Label>

              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <svg className="mx-auto h-12 w-12 text-neutral-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <div className="flex flex-col sm:flex-row text-sm text-neutral-6000">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span>Unggah File Anda</span>
                      <input id="file-upload" name="image" type="file" className="sr-only" />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-neutral-500">
                    PNG, JPG, GIF sampai 2 MB <br></br>
                    (Disarankan ukuran 1640x924)
                  </p>
                </div>
              </div>
            </div>
            <label className="block md:col-span-2">
              <Label> Isi Artikel</Label>
              <input id="my-file" type="file" name="my-file" style={{ display: "none" }} />
              <Editor
                apiKey="a7j6ev79aa78cl6cd79vokfrh5tdjiv9gg6ao85yuus4tly6"
                // initialValue="<p>This is the initial content of the editor</p>"
                init={{
                  skin: "snow",
                  icons: "thin",
                  placeholder: "Ask a question or post an update...",

                  height: 1000,
                  menubar: true,
                  plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen textcolor ", "insertdatetime media table paste code help wordcount", "image code"],
                  image_title: true,
                  automatic_uploads: true,
                  file_picker_types: "image",
                  textcolor_rows: "4",
                  file_picker_callback: function (callback, value, meta) {
                    if (meta.filetype === "image") {
                      var input: any = document.getElementById("my-file");
                      input.click();
                      input.onchange = function () {
                        var file = input.files[0];
                        var reader = new FileReader();
                        reader.onload = function (e: any) {

                          callback(e.target.result, {
                            alt: file.name,
                          });
                        };
                        reader.readAsDataURL(file);
                      };
                    }
                  },

                  content_style: "body { font-family: Poppins; }",
                  font_formats: "Poppins",

                  toolbar: "undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ",
                }}
                onEditorChange={handleEditorChange}
                outputFormat="html"
              />
            </label>

            {loading ? <Spinner color="success" /> : ""}
            <ButtonPrimary className="md:col-span-2" type="submit">
              Kirim
            </ButtonPrimary>
          </form>
        </div>
      </LayoutPage>
    </div>
  );
};

export default DashboardSubmitPost;
