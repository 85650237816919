/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";
import {
  EVENT_URL,
  EVENTREGISTRATION_URL,
  ORDER_URL,
} from "../../../utils/endpoint";
import {
  LOCALSTORAGE_USERDETAIL,
  LOCALSTORAGE_TOKEN,
} from "../../../utils/Types";
import Label from "components/Label/Label";
import axios from "axios";
import { Alert } from "reactstrap";
import ButtonPrimary from "components/Button/IPIButtonPrimary";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import Input from "components/Input/IPIInput";

export const Sidebar = () => {
  const params: any = useParams();

  const history: any = useHistory();

  const [event, setEvent]: any = useState([]);
  const [status, setStatus]: any = useState("");

  const [loading, setLoading] = useState(false);
  const [isAlertOn, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const errorHandling = (msg: any) => {
    setErrorMsg(` ${JSON.stringify(msg)}`);
    setLoading(false);
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 10000);
  };

  const localdata: any = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_USERDETAIL) || "null"
  );
  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(`${EVENT_URL}${params.id}`)
      .then((res: any) => {
        setEvent(res.data);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });

    if (localdata !== null) {
      axios
        .get(
          `https://api.ibupembelajar.id/api-ipi/statuseventregisterbyiduser/${localdata.id}/idevent/${params.id}/`,
          options
        )
        .then((res: any) => {
          setStatus(res.data);
        })
        .catch((error: any) => {
          console.log(error.response.data);
        });
    }
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    setLoading(true);
    const data = new FormData();
    data.append("user_id", localdata.id);
    data.append("event_ipi", event.id);
    if (event.is_upload_image) {
      if (e.target.image.files[0]) {
        data.append("image", e.target.image.files[0]);
      }
    }
    let totalPrice = 0;
    data.append("is_subscribe	", event.price !== 0 ? "false" : "true");
    if (event.price !== 0) {
      totalPrice = event.price + 4000 + 440 + 1500;
    } else {
      totalPrice = event.price;
    }

    axios
      .post(EVENTREGISTRATION_URL, data, options)
      .then(() => {
        const order = new FormData();
        order.append("user_id", localdata.id);
        order.append("event_ipi", event.id);
        order.append("total_price	", totalPrice.toString());
        order.append("payment_method	", "Xendit");

        axios
          .post(ORDER_URL, order, options)
          .then(() => {
            errorHandling("posting data berhasil");
            window.location.reload();
            setLoading(false);
          })
          .catch((error) => {
            errorHandling(error.response.data);
            setLoading(false);
          });
      })
      .catch((error) => {
        errorHandling(error.response.data);
        setLoading(false);
      });
  };

  var dateEvent = moment().format("YYYY-MM-DD");
  var isValidEvent = moment(dateEvent).isSameOrBefore(`${event.date}`);

  return (
    <div className={`nc-SingleSidebar space-y-6`}>
      <div
        className={`nc-PageSubcription space-y-6`}
        data-nc-id="PageSubcription"
      >
        {isAlertOn && (
          <Alert onClick={() => setAlert(false)} color="danger">
            {errorMsg}
          </Alert>
        )}
        {localdata != null ? ( // cek user
          status.status === "Yes" ? (
            <>
              <Alert color="primary">
                Halo, Silahkan pilih menu dibawah ini
              </Alert>

              <ButtonPrimary
                href="/dashboard/orders"
                className="md:col-span-2"
                type="submit"
              >
                Dashboard
              </ButtonPrimary>
              {status.status_payment === "Belum Lunas" && (
                <ButtonPrimary className="md:col-span-2" type="submit">
                  <a
                    href={status.payment_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Bayar
                  </a>
                </ButtonPrimary>
              )}
              {(status.status_payment === "Lunas" ||
                status.is_subscribe) && (
                  <Alert color="primary">
                    {event.message} (silahkan{" "}
                    <a href={event.url_subscribed} style={{ color: "#00c2cb" }}>
                      Klik Disini
                    </a>
                    {" "}untuk bergabung ke Whatsapp grup
                    )
                  </Alert>
                )}
            </>
          ) : (
            <>
              {isValidEvent && (
                <>
                  <Alert color="primary">Halo, Silahkan daftar event ini</Alert>

                  <form onSubmit={handleSubmit}>
                    <label className="block">
                      <Label>Nama</Label>
                      <Input
                        name="name"
                        value={localdata.fullname}
                        disabled
                        type="text"
                        className="mt-1"
                        min="0"
                      />
                    </label>
                    <label className="block">
                      <Label>Harga</Label>
                      <Input
                        name="price"
                        value={event.price}
                        disabled
                        type="text"
                        className="mt-1"
                        min="0"
                      />
                    </label>
                    {/* <label className="block">
                      <Label>Transaksi</Label>
                      <Input
                        name="transaction"
                        value="5995"
                        disabled
                        type="text"
                        className="mt-1"
                        min="0"
                      />
                    </label> */}
                    {event.is_upload_image && (
                      <label className="block">
                        <Label>Upload bukti aktif berlangganan</Label>
                        <input className="mt-1" type="file" name="image" />
                      </label>
                    )}
                    {loading ? <div>loading...</div> : ""}

                    <ButtonPrimary className="md:col-span-2 mt-8" type="submit">
                      Daftar Sekarang
                    </ButtonPrimary>
                  </form>
                </>
              )}
              {!isValidEvent && (
                <Alert color="primary">Event ini sudah ditutup</Alert>
              )}
            </>
          )
        ) : (
          <>
            <Alert color="primary">
              Upps, Kamu belum login untuk mendaftar event ini
            </Alert>

            <Link
              to={{
                pathname: "/login",
                state: history.location.pathname,
              }}
            >
              <ButtonPrimary type="submit">Masuk</ButtonPrimary>
            </Link>
          </>
        )}
      </div>
      {/* {
        loadingPost1 && 
        <WidgetCategories categories={postCategory1} />
      }
      {
        loading && 
        <WidgetTags tags={category} />
      }
      <WidgetAuthors authors={authors} />
      {
        loadingPost && 
        <WidgetPosts posts={post} />
      } */}
    </div>
  );
};
