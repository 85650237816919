import LayoutPage from "components/LayoutPage/IPILayoutPage";
import React, { ComponentType, FC, useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import DashboardEditProfile from "./DashboardEditProfile";
import DashboardPosts from "./PagePost/DashboardPosts";
import DashboardEvents from "./PageDashboardEvent/DashboardEvents";
import DashboardCompetitions from "./PageDashboardCompetition/DashboardCompetitions";
import DashboardRoot from "./DashboardRoot";
import { Helmet } from "react-helmet";
import DashboardOrder from "./PageDashboardOrder/DashboardOrder";

export interface PageDashboardProps {
  className?: string;
}

interface DashboardLocationState {
  "/user"?: {};
  "/posts"?: {};
  "/events"?: {};
  "/orders"?: {};
  "/competitions"?: {};
  "/edit-profile"?: {};
  "/subscription"?: {};
  "/billing-address"?: {};
  "/submit-post"?: {};
  "/account"?: {};
}

interface DashboardPage {
  sPath: keyof DashboardLocationState;
  exact?: boolean;
  component: ComponentType<Object>;
  emoij: string;
  pageName: string;
}

const subPages: DashboardPage[] = [
  {
    sPath: "/user",
    exact: true,
    component: DashboardRoot,
    emoij: "🕹",
    pageName: "Dashboard",
  },
  {
    sPath: "/posts",
    component: DashboardPosts,
    emoij: "✏",
    pageName: "Artikel",
  },
  {
    sPath: "/events",
    component: DashboardEvents,
    emoij: "📌",
    pageName: "Kegiatan & Webinar",
  },
  {
    sPath: "/orders",
    component: DashboardOrder,
    emoij: "📌",
    pageName: "Order Kegiatan Webinar",
  },
  {
    sPath: "/competitions",
    component: DashboardCompetitions,
    emoij: "🔎",
    pageName: "Kompetisi",
  },
  {
    sPath: "/edit-profile",
    component: DashboardEditProfile,
    emoij: "🛠",
    pageName: "Ubah Profil",
  },
];

const PageDashboard: FC<PageDashboardProps> = ({ className = "" }) => {
  let { path, url } = useRouteMatch();

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <div className={`nc-PageDashboard ${className}`} data-nc-id="PageDashboard">
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <LayoutPage
        subHeading="View your dashboard, manage your Posts, Subscription, edit password and profile"
        headingEmoji="⚙"
        heading={`Dashboard`}
      >
        <div className="flex flex-col space-y-8 xl:space-y-0 xl:flex-row">
          {/* SIDEBAR */}

          <div className="flex-shrink-0 max-w-xl xl:w-80 xl:pr-8">
            <ul className="text-base space-y-1 text-neutral-6000 dark:text-neutral-400">
              {subPages.map(({ sPath, pageName, emoij }, index) => {
                return (
                  <li key={index}>
                    <NavLink
                      className="flex px-6 py-2.5 font-medium rounded-lg hover:text-neutral-800 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-100"
                      to={`${url}${sPath}`}
                      activeClassName="bg-neutral-100 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-100"
                    >
                      <span className="w-8 mr-1">{emoij}</span>
                      {pageName}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="border border-neutral-100 dark:border-neutral-800 md:hidden"></div>
          <div className="flex-grow">
            <Switch>
              {subPages.map(({ component, sPath, exact }, index) => {
                return (
                  <Route
                    key={index}
                    exact={exact}
                    component={component}
                    path={!!sPath ? `${path}${sPath}` : path}
                  />
                );
              })}
              <Redirect to={path + "/user"} />
            </Switch>
          </div>
        </div>
      </LayoutPage>
    </div>
  );
};

export default PageDashboard;
