import { FC, useEffect, useState } from "react";
import LayoutPage from "components/LayoutPage/IPILayoutPage";
import Input from "components/Input/IPIInput";
import ButtonPrimary from "components/Button/IPIButtonPrimary";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { PASSWORD_RESET_CONFIRM_URL } from "utils/endpoint";
import { Alert } from "reactstrap";

export interface PageChangePassProps {
  className?: string;
}

const PageChangePass: FC<PageChangePassProps> = ({ className = "" }) => {
  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  const [isAlertOn, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const params : any = useParams();

  const errorHandling = (msg: any) => {
    if (msg.status === "OK") {
      setErrorMsg("Password berhasil diperbaharui, silahkan login kembali")
      setTimeout(() => { setAlert(false); }, 3000)
      history.push('/login');
      window.location.reload()
    } else {
      setErrorMsg(` ${JSON.stringify(msg)}`);
    }
    setLoading(false);
    setAlert(true);
    setTimeout(() => { setAlert(false); }, 5000)
  };

  const changepassHandle = (e: any) => {
    e.preventDefault();
    if(e.target.password1.value !== e.target.password2.value){
      errorHandling("Konfirmasi password tidak sama. Silahkan cek kembali");
      return;
    }

    const data = new FormData();
    data.append('token', e.target.token.value);
    data.append('password', e.target.password1.value);
    setLoading(true);
    axios.post(PASSWORD_RESET_CONFIRM_URL, data)
      .then((res) => {
        errorHandling(res.data);
        setLoading(false);
        // history.push('/');
        // window.location.reload()
      }).catch((error) => {
        errorHandling(error.response.data);
      });
  }

  return (
    <div
      className={`nc-PageChangePass ${className}`}
      data-nc-id="PageChangePass"
    >
      <Helmet>
        <title>Ganti Kata Sandi</title>
      </Helmet>
      <LayoutPage
        subHeading="Masukan Password Lama & Baru Anda"
        headingEmoji="🔐"
        heading="Ganti Kata Sandi"
      >
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={changepassHandle}>
            <Input
              type="hidden"
              name="token"
              className="mt-1"
              value={params.token}
            />

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Password Baru
              </span>
              <Input
                type="password"
                name="password1"
                placeholder="xxxxxxxxx"
                className="mt-1"
              />
            </label>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Ulangi Password Baru
              </span>
              <Input
                type="password"
                name="password2"
                placeholder="xxxxxxxxx"
                className="mt-1"
              />
            </label>

            {isAlertOn && <Alert onClick={() => setAlert(false)} type="danger">{errorMsg}</Alert>}
            <div className="flex items-center justify-center">
              {isLoading && <div>loading...</div>}
            </div>

            <ButtonPrimary type="submit">Kirim</ButtonPrimary>
          </form>

        </div>
      </LayoutPage>
    </div>
  );
};

export default PageChangePass;
