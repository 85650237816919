import { FC, useEffect, useState } from "react";
import HeaderFilter from "./IPIHeaderFilter";
import Card12 from "containers/PageHome/SectionPosts/IPICard12";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import { HOMEPAGEPOST_URL, POSTCATEGORY_URL, POST_URL } from "utils/endpoint";
import { useHistory } from "react-router-dom";
import PostCardMeta from "components/PostCardMeta/IPIPostCardMeta";
import NcImage from "components/NcImage/IPINcImage";

export interface SectionMagazine5Props {
  heading?: any;
}
const DetailHandler = (history: any, info: any) => {
  history.push({
  pathname:`/article/${info.id}`,
  state: { data: info }});
};
const SectionHomepageArtikel: FC<SectionMagazine5Props> = ({
  heading = "Latest Articles 🎈 ",
}) => {
  const [posts, setPosts]: any = useState([]);
  const [category, setCategory]: any = useState([]);
  const history: any = useHistory();

  useEffect(() => {
    axios
      .get(POSTCATEGORY_URL)
      .then((res: any) => {
        const temp1 = [
          {
            id: 0,
            image: null,
            is_public: true,
            name: "Semua",
          },
        ];
        const temp = temp1.concat(res.data);
        setCategory(temp);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
    axios
      .get(HOMEPAGEPOST_URL)
      .then((res: any) => {
        setPosts(res.data);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
  }, []);
  const [tabActive, setTabActive] = useState<string>("Semua");

  const [filterProduct, setFilterProduct]: any = useState(posts);
  const [filter, setFilter]: any = useState("Semua");
  const FilteredProduct = (data: any) => {
    if (data === tabActive) {
      return;
    }
    setTabActive(data);
    setFilter(data);
    const updateProduct = posts.filter((dataNow: any) => {
      return (
        dataNow.subcategory2_detail.subcategory1_detail.category_detail.name ===
        data
      );
    });
    setFilterProduct(updateProduct);
  };

  const handleClickTab = (item: any) => {
    if (item === tabActive) {
      return;
    }
    FilteredProduct(item);
  };
  // sorting
  posts.sort((firstItem: any, secondItem: any) => secondItem.id - firstItem.id);

  let displayPosts: any;
  if (filter === "Semua") {
    displayPosts = posts;
  } else {
    displayPosts = filterProduct;
  }

  return (
    <div className="nc-SectionMagazine5">
      <HeaderFilter
        tabActive={tabActive}
        tabs={category}
        heading={heading}
        onClickTab={handleClickTab}
      />
      {!displayPosts.length && <span>Nothing we found!</span>}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 md:gap-7">
        {displayPosts[0] && <Card12 post={displayPosts[0]} />}
        <div className="grid grid-cols-1 gap-5 md:gap-7">
          {displayPosts
            .filter((_: any, i: number) => i < 4 && i > 0)
            .map((data: any) => (
              <div key={data.id} className={`nc-Card13 relative flex`} data-nc-id="Card13">
              <div className="flex flex-col h-full py-2">
                <h2 className={`nc-card-title block font-semibold text-base`}>
                  <div onClick={() => DetailHandler(history, data)} className="line-clamp-2" title={data.title}>
                    {data.title}
                  </div>
                </h2>
                <span className="hidden sm:block my-3 text-neutral-500 dark:text-neutral-400 ">
                  <span className="line-clamp-2"> {ReactHtmlParser(data.content)}</span>
                </span>
                <span className="mt-4 block sm:hidden text-sm text-neutral-500 ">
                  {data.created_at}
                </span>
                <div className="mt-auto hidden sm:block">
                  <PostCardMeta meta={ data } />
                </div>
              </div>
        
              <div
                onClick={() => DetailHandler(history, data)}
                className={`block relative h-full flex-shrink-0 w-2/5 sm:w-1/3 ml-3 sm:ml-5`}
              >
                <NcImage
                  containerClassName="absolute inset-0 "
                  className="object-cover w-full h-full rounded-xl sm:rounded-3xl"
                  src={data.image}
                  alt={data.title}
                />
              </div>
            </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SectionHomepageArtikel;
