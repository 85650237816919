/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useEffect, useState } from "react";
import imgAdsDef from "images/ads1.png";
import imgAdsDef2 from "images/ads2.png";

export interface SectionAdsProps {
  className?: string;
  imgAds?: string;
  imgAds2?: string;
}

const SectionAds: FC<SectionAdsProps> = ({
  className = "",
  imgAds = imgAdsDef,
  imgAds2 = imgAdsDef2,
}) => {
  const [toggle, setToogle] = useState(false);

  const timeOutHandler = () => {
    setToogle(!toggle);
  }

  useEffect(() => {
    setInterval(() => {
      timeOutHandler();
    }, 4000);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    // <div>
      <a
        // href="https://bit.ly/3oOdgKk"
        href="https://trial.educourse.id/IBUPEMBELAJAR/"
        target="_blank"
        className={`nc-SectionAds block text-center mx-auto ${className}`}
        id="adsIpi"
      >
        {/* <span className="text-xs text-neutral-500">- Sponsorship -</span> */}
        {toggle? <img className="mx-auto" src={imgAds} alt="" />: <img className="mx-auto" src={imgAds2} alt="" />}
      </a>
    // </div>
  );
};

export default SectionAds;
