import {
  NavItemType,
} from "components/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

const dashboardChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/participant-project",
    name: "Karya Peserta",
  },
];
export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Beranda",
    // type: "dropdown",
    // children: demoChildMenus,
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "Tentang IPI",
    // type: "megaMenu",
    // megaMenu: megaMenuDemo,
  },

  {
    id: ncNanoId(),
    href: "/events",
    name: "Webinar",
    // type: "megaMenu",
    // megaMenu: megaMenu3ItemDemo,
  },
  {
    id: ncNanoId(),
    href: "/articles",
    name: "Artikel",
    // type: "dropdown",
    // children: templateChilds,
  },
  {
    id: ncNanoId(),
    href: "/competitions",
    name: "Kompetisi",
    type: "dropdown",
    children: dashboardChildMenus,
  },
  {
    id: ncNanoId(),
    href: "/library",
    name: "Perpustakaan",
    // type: "dropdown",
    // children: templateChilds,
  },
];
