import React, { FC } from "react";
import NcImage from "components/NcImage/IPINcImage";
import PostCardMeta from "components/PostCardMeta/IPIPostCardMeta";
import { useHistory } from "react-router-dom";

export interface Card3SmallProps {
  className?: string;
  post: any;
}
const DetailHandler = (history: any, info: any) => {
  history.push({
  pathname:`/article/${info.id}`,
  state: { data: info }});
};
const Card3Small: FC<Card3SmallProps> = ({ className = "h-full", post }) => {
  const { title, image } = post;
  const history = useHistory();

  return (
    <div
      className={`nc-Card3Small relative flex flex-col-reverse sm:flex-row sm:justify-between sm:items-center ${className}`}
      data-nc-id="Card3Small"
    >
      <div onClick={() => DetailHandler(history, post)} className=" absolute inset-0" title={title}></div>
      <div className="relative space-y-2">
        <PostCardMeta meta={ post } />
        <h2 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          <div onClick={() => DetailHandler(history, post)} className=" line-clamp-2" title={title}>
            {title}
          </div>
        </h2>
      </div>

      <div
        onClick={() => DetailHandler(history, post)}
        title={title}
        className={`block sm:w-20 flex-shrink-0 relative rounded-lg overflow-hidden mb-5 sm:ml-4 sm:mb-0 group`}
      >
        <div className={`w-full h-0 aspect-w-16 aspect-h-9 sm:aspect-h-16`}>
          <NcImage
            containerClassName="absolute inset-0"
            className="nc-will-change-transform object-cover w-full h-full group-hover:scale-110 transform transition-transform duration-300"
            src={image}
            title={title}
          />
        </div>
      </div>
    </div>
  );
};

export default Card3Small;
