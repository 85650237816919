/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { LOCALSTORAGE_TOKEN } from "../../../../utils/Types";
import axios from "axios";
import { CEKANSWEREVENTUSER_URL } from "utils/endpoint";

const Certificate = (person: any) => {
  const [isCheckCertificate, setIsCheckCertificate] = useState(false);

  useEffect(() => {
    // cek status jawaban survey
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(`${CEKANSWEREVENTUSER_URL + person.course.event_ipi}/`, options)
      .then((res: any) => {
        setIsCheckCertificate(res.data.status);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
  }, []);

  return (
    <div className="App">
      {isCheckCertificate ? (
        <a
          href={`https://api.ibupembelajar.id/certificateevent/${person.course.id}/`}
          target="_blank"
        >
          Download Sertifikat Anda
        </a>
      ) : (
        <p>Harap mengisi evaluasi dahulu dan sertifikat akan keluar</p>
      )}
    </div>
  );
};

export default Certificate;
