import React, {useEffect, useState} from "react";
import NcImage from "components/NcImage/IPINcImage";
import axios from "axios";
import { EVENTUSER_URL } from '../../../utils/endpoint';
import { LOCALSTORAGE_TOKEN } from '../../../utils/Types';
import ButtonEdit from "components/Button/ButtonEdit";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

const DetailHandler = (history: any, info: any) => {
  history.push({
    pathname:`/event-user/detail/${info.id}`,
    state: { data: info }});
};
const DashboardPosts = () => {
  const history = useHistory();
  const [events, setEvent]: any = useState([]);

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
    },
    };
    axios
      .get(EVENTUSER_URL, options)
      .then((res : any) => {
        setEvent(res.data);
      })
      .catch((error : any) => {
        console.log(error.response.data);
      });
  }, []);

  const data = {
    columns: [
      {
        label: "Index",
        field: "index",
        sort: "asc",
        width: 20,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 20,
      },
      {
        label: "Kegiatan & Webinar",
        field: "event",
        sort: "asc",
        width: 20,
      },
      {
        label: "Tanggal",
        field: "date",
        sort: "asc",
        width: 20,
      },
      {
        label: "Tipe",
        field: "type",
        sort: "asc",
        width: 20,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 20,
      },
    ],
    rows: events.map((e: any, index: any) => ({
      index: `${index + 1}`,
      image: [
        <NcImage
          containerClassName="flex-shrink-0 h-12 w-12 rounded-lg overflow-hidden lg:h-14 lg:w-14"
          src={e.event_detail.image}
        />,
      ],
      event: e.event_detail.title,
      date: e.event_detail.date,
      type: e.event_detail.type,
      action: [
        <ButtonEdit onClick={() => DetailHandler(history, e)}>
        Detail
      </ButtonEdit>,
      ],
    })),
  };
  return (
    <div className="flex flex-col space-y-8">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full px-1 sm:px-6 lg:px-8">
          <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
          <MDBDataTable
              noBottomColumns={true}
              theadTextWhite={false}
              responsive
              bordered
              data={data}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPosts;
