import ReactHtmlParser from "react-html-parser"
const SingleContentDemo = (content:any) => {
  console.log(content)
  return (
    <>
     {ReactHtmlParser(content.content.event_detail.description)}
    </>
  );
};

export default SingleContentDemo;
