import React, { useEffect, useState } from "react";
import Input from "components/Input/IPIInput";
import ButtonPrimary from "components/Button/IPIButtonPrimary";
// import Textarea from "components/Textarea/Textarea";
import Label from "components/Label/Label";
import axios from "axios";
import { Alert, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { LOCALSTORAGE_USERDETAIL, LOCALSTORAGE_TOKEN } from "../../../../utils/Types";
import { ALLPOST_URL, COMPETITIONS_URL } from "../../../../utils/endpoint";
import { Editor } from "@tinymce/tinymce-react";

const DashboardSubmitPost = (stateOri:any) => {
  const { location } = stateOri;
  const { state } = location;
  const { data } = state;

  // initialvalue
  const [title, setTitle]: any = useState(data.post_detail.title);


  const [competition, setCompetition]: any = useState([]);
  const [loadingCompetition, setLoadingCompetition]: any = useState(false);

  useEffect(() => {
    axios
      .get(`${COMPETITIONS_URL}${data.post_competition_id}`)
      .then((res: any) => {
        setCompetition(res.data);
        setLoadingCompetition(true);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
  }, []);


  const [myUser, setMyUser]: any = useState(null);
  const [loadingUser, setLoadingUser]: any = useState(false);

  useEffect(() => {
    const localdata: any = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL) || "{}");
    setMyUser(localdata);
    setLoadingUser(true);
  }, []);

  const [loading, setLoading] = useState(false);
  const [isAlertOn, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const history = useHistory();

  const errorHandling = (msg: any) => {
    setErrorMsg(` ${JSON.stringify(msg)}`);
    setLoading(false);
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 10000);
  };

  const [content, setContents] = useState(data.post_detail.content);
  const handleEditorChange = (contents: any, editor: any) => {

    setContents(contents);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const updatedata = new FormData();
    updatedata.append("author_id", myUser.id);
    updatedata.append("title", e.target.title.value);
    updatedata.append("subcategory_2_id", e.target.subcategory_2_id.value);
    if(e.target.raw_file.file){
      updatedata.append("raw_file", e.target.raw_file.files[0]);
    }
    updatedata.append("content", content);
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };

    axios
      .patch(`${ALLPOST_URL}${data.post_id}/`, updatedata, options)
      .then((response) => {
        // const userdetail = userData.data;
        // localStorage.setItem(LOCALSTORAGE_USERDETAIL, JSON.stringify(userData.data));
        // window.location.reload();
        // console.log('resp', response)
        // const dataMember = new FormData();
        // dataMember.append("post_competition_id", params.id);
        // dataMember.append("post_id", response.data.id);
        // axios.patch(`${COMPETITIONSMEMBERS_URL}${data.id}/`, dataMember, options).then(() => {
        //   errorHandling("posting data berhasil");
        //   history.push({
        //     pathname: "/dashboard/competitions",
        //   });
        //   // window.location.reload();
        // });
        errorHandling("posting data berhasil");
          // window.location.reload();
          history.push({
            pathname: "/dashboard/competitions",
          });
        setLoading(false);
      })
      .catch((error) => {
        errorHandling(error.response.data);
        setLoading(false);
      })
      .catch((error) => {
        errorHandling(error.response.data);
      });
  };
  return (
    <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
      {isAlertOn && (
        <Alert onClick={() => setAlert(false)} color="danger">
          {errorMsg}
        </Alert>
      )}

      <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
        <input name="user_id" type="hidden" value={loadingUser && myUser.id} />
        {loadingCompetition && competition.subcategory_2_detail.is_public ? (
          <label className="block md:col-span-2">
            <Label>Judul *</Label>
            <Input 
              type="text" 
              className="mt-1" 
              name="title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </label>
        ) : (
          <input name="title" type="hidden" value={loadingCompetition & competition.name} />
        )}
        <input name="subcategory_2_id" type="hidden" value={loadingCompetition && competition.subcategory_2_id} />
        {/* <label className="block md:col-span-2">
          <Label>Post Excerpt</Label>
          <Textarea className="mt-1" rows={4} />
          <p className="mt-1 text-sm text-neutral-500">
            Brief description for your article. URLs are hyperlinked.
          </p>
        </label> */}
        {/* <label className="block">
          <Label>Category</Label>

          <Select className="mt-1">
            <option selected disabled>Pilih Salah Satu</option>
            {loadingCategory && category2.map((data : any) => (
              <option key={data.id} value={data.id}>{data.name}</option>
            ))
            }
          </Select>
        </label> */}
        {/* <label className="block">
          <Label>Tags</Label>

          <Input type="text" className="mt-1" />
        </label> */}

        {/* <div className="block md:col-span-2">
          <Label>Featured Image</Label>

          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md">
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-neutral-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              <div className="flex flex-col sm:flex-row text-sm text-neutral-6000">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                >
                  <span>Upload a file</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-neutral-500">
                PNG, JPG, GIF up to 2MB
              </p>
            </div>
          </div>
        </div> */}
        <label className="block md:col-span-2">
          <Label>Unggah File word/video/foto mentah (opsional)</Label>
          <Input type="file" name="raw_file" />
        </label>
        {loadingCompetition && competition.subcategory_2_detail.is_public ? (
          <label className="block md:col-span-2">
            <Label> Post Content Artikel dalam mengikuti lomba</Label>
            <input id="my-file" type="file" name="my-file" style={{ display: "none" }} />
            <Editor
              apiKey="a7j6ev79aa78cl6cd79vokfrh5tdjiv9gg6ao85yuus4tly6"
              initialValue={data.post_detail.content}
              init={{
                skin: "snow",
                icons: "thin",
                placeholder: "Ask a question or post an update...",

                height: 1000,
                menubar: true,
                plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen textcolor ", "insertdatetime media table paste code help wordcount", "image code"],
                image_title: true,
                automatic_uploads: true,
                file_picker_types: "image",
                textcolor_rows: "4",
                file_picker_callback: function (callback, value, meta) {
                  if (meta.filetype === "image") {
                    var input: any = document.getElementById("my-file");
                    input.click();
                    input.onchange = function () {
                      var file = input.files[0];
                      var reader = new FileReader();
                      reader.onload = function (e: any) {
                        // console.log('name',e.target.result);
                        // console.log(e);
                        callback(e.target.result, {
                          alt: file.name,
                        });
                      };
                      reader.readAsDataURL(file);
                    };
                  }
                },
                paste_data_images: true,
                toolbar: "undo redo | styleselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ",
              }}
              onEditorChange={handleEditorChange}
              outputFormat="html"
              // toolbar="code"
            />
          </label>
        ) : (
          <label className="block md:col-span-2">
            <Label>Url video/foto</Label>
            <Input
              onChange={(e) => {
                setContents(e.target.value);
              }}
              type="url"
              className="mt-1"
              required
            />
          </label>
        )}
        {loading ? <Spinner color="success" /> : ""}
        <ButtonPrimary className="md:col-span-2" type="submit">
          Kirim
        </ButtonPrimary>
      </form>
    </div>
  );
};

export default DashboardSubmitPost;
