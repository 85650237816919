import React, { FC, useEffect } from "react";
import ButtonPrimary from "components/Button/IPIButtonPrimary";
import Input from "components/Input/IPIInput";
import Label from "components/Label/Label";
import LayoutPage from "components/LayoutPage/IPILayoutPage";
import SocialsList from "components/SocialsList/SocialsList";
import Textarea from "components/Textarea/Textarea";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/IPISectionSubscribe2";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ALAMAT",
    desc: "Golden Madrid 2 Blok G No. 22, Jalan Letnan Sutopo, Kecamatan Serpong, Tangerang Selatan, Banten, Indonesia",
  },
  {
    title: "💌 EMAIL",
    desc: "ibupembelajarindonesia@gmail.com",
  },
  {
    title: "☎ PHONE",
    desc: "+62 852-1810-1645",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);
  return (
    <div className={`nc-PageContact ${className}`} data-nc-id="PageContact">
      <Helmet>
        <title>Kontak Kami</title>
      </Helmet>
      <LayoutPage
        subHeading="Kirim pesan kepada kami dan kami akan membalas Anda."
        headingEmoji=""
        heading="Kontak Kami"
      >
        <div className="grid gap-8 lg:grid-cols-2">
          <div className="max-w-sm space-y-6">
            {info.map((item, index) => (
              <div key={index}>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  {item.title}
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  {item.desc}
                </span>
              </div>
            ))}
            {/* <div>
              <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                🌏 Sosial Media
              </h3>
              <SocialsList className="mt-2" />
            </div> */}
          </div>
          <div className="border border-neutral-100 dark:border-neutral-700 lg:hidden"></div>
          <div>
            <form className="grid grid-cols-1 gap-6" action="#" method="post">
              <label className="block">
                <Label>Nama Lengkap</Label>

                <Input placeholder="Example Doe" type="text" className="mt-1" />
              </label>
              <label className="block">
                <Label>Alamat Email</Label>

                <Input
                  type="email"
                  placeholder="example@example.com"
                  className="mt-1"
                />
              </label>
              <label className="block">
                <Label>Isi Pesan</Label>

                <Textarea className="mt-1" rows={6} />
              </label>
              <ButtonPrimary type="submit">Kirim</ButtonPrimary>
            </form>
          </div>
        </div>
      </LayoutPage>

      {/* OTHER SECTIONS  */}
      <div className="container pb-16 lg:pb-28">
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageContact;
