import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { HOMEEVENT_URL } from "utils/endpoint";
import Heading from "components/Heading/IPIHeading";
import SectionAds from "containers/PageHome/IPISectionAds";
import NcImage from "components/NcImage/IPINcImage";
import { useHistory } from "react-router-dom";
import moment from "moment";

const DetailHandler = (history: any, info: any) => {
  history.push({
    pathname: `/event/${info.id}`,
    state: { data: info },
  });
};

const PageEventListing: React.FC = () => {
  const history = useHistory();
  const [events, setEvents]: any = useState([]);
  const [isLoading, setLoading]: any = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(HOMEEVENT_URL)
      .then((res: any) => {
        setEvents(res.data);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });

    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-demo-6 theme-lightBlue-coolGrey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <div className="nc-PageHomeDemo6 relative [ nc-section-rounded-md ]">
      <div className="flex items-center justify-center">
        {isLoading && <div>loading...</div>}
      </div>
      <Helmet>
        <title>Kegiatan & Webinar</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          // crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>

      <div className="relative overflow-hidden">
        <div className="container relative">
          <div className={`nc-SectionMagazine9 relative pt-16 lg:pt-24`}>
            <SectionAds className=" sm: pt-8" /> <br></br>
            <br></br>
            <Heading desc={"Temukan event dan acara menarik di sini"}>
              Kegiatan Ibu Pembelajar Indonesia
            </Heading>
            <div
              className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8`}
            >
              {events?.map((p: any) => (
                <div
                key={p.id}
                  className={`nc-Card9 relative flex flex-col group rounded-3xl overflow-hidden h-full`}
                  data-nc-id="Card9"
                >
                  <div
                    className={`flex items-start relative w-full aspect-w-3 aspect-h-3 sm:aspect-h-4`}
                  ></div>

                  <div onClick={() => DetailHandler(history, p)}>
                    <NcImage
                      containerClassName="absolute inset-0 rounded-3xl"
                      className="object-cover w-full h-full rounded-3xl"
                      src={p.image}
                    />
                    <span className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 transition-opacity"></span>
                  </div>

                  <div
                    onClick={() => DetailHandler(history, p)}
                    className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black opacity-50"
                  ></div>
                  <div className="absolute bottom-0 inset-x-0 p-4 flex flex-col flex-grow">
                    <div
                      onClick={() => DetailHandler(history, p)}
                      className="absolute inset-0"
                    ></div>
                    <div className="inline-flex items-center text-xs text-neutral-300">
                      <div className="block ">
                        <h2 className="block text-lg font-semibold text-white ">
                          <span className="line-clamp-2" title={p.title}>
                            {p.title}
                          </span>
                        </h2>
                        <div
                          onClick={() => DetailHandler(history, p)}
                          className="flex mt-2.5 relative"
                        >
                          <span className="mx-[6px] font-medium">
                            Tanggal Event
                          </span>
                          <span className="font-normal truncate">
                            {moment(p.date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 mt-8`}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageEventListing;
