import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "components/Avatar/Avatar";
import NcImage from "components/NcImage/IPINcImage";
import moment from "moment";
export interface Card11Props {
  className?: string;
  post: any;
  ratio?: string;
  hiddenAuthor?: boolean;
}
const DetailHandler = (history: any, info: any) => {
  history.push({
    pathname: `/article/${info.title.split(" ").join("-")}`,
    state: { data: info },
  });
};

const Card11: FC<Card11Props> = ({
  className = "h-full",
  post,
  ratio = "aspect-w-4 aspect-h-4",
}) => {
  const { title, image, author_detail, created_at } = post;
  const history = useHistory();

  return (
    <div
      className={`nc-Card11 relative flex flex-col group [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="Card11"
    >
      <div
        className={`block flex-shrink-0 relative w-full rounded-t-xl overflow-hidden ${ratio}`}
      >
        <div>
          <div
            className={`nc-PostFeaturedMedia relative ${className}`}
            data-nc-id="PostFeaturedMedia"
          >
            <NcImage containerClassName="absolute inset-0" src={image} />
          </div>
        </div>
      </div>
      <div
        onClick={() => DetailHandler(history, post)}
        className="absolute inset-0"
      ></div>

      <div className="p-4 flex flex-col flex-grow space-y-3">
      <div
      className={`nc-PostCardMeta inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 text-xs leading-none`}
      data-nc-id="PostCardMeta"
    >
      { author_detail !== undefined && 
        <div className="relative flex items-center space-x-2">
            <Avatar
              radius="rounded-full"
              sizeClass="h-7 w-7 text-sm"
              imgUrl={author_detail?.photo}
              userName={author_detail?.fullname}
            />
          <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
            {author_detail?.fullname}
          </span>
        </div>
      }
      <>
        <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
          ·
        </span>
        <span className="text-neutral-500 dark:text-neutral-400 font-normal"> 
          {moment(created_at, 'YYYY-MM-DD | HH:mm:ss').format('DD-MM-YYYY | HH:mm')}
        </span>
      </>
    </div>
        <h2 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100 ">
          <div
            onClick={() => DetailHandler(history, post)}
            className="line-clamp-2"
            title={title}
          >
            {title}
          </div>
        </h2>
      </div>
    </div>
  );
};

export default Card11;
