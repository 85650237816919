import React, { FC, useEffect, useState } from "react";
import {COMPETITIONS_URL, COMPETITIONSREGISTRATION_URL} from "../../../utils/endpoint"
import { LOCALSTORAGE_USERDETAIL, LOCALSTORAGE_TOKEN } from '../../../utils/Types';
import axios from "axios";
import { Alert } from 'reactstrap';
import ButtonPrimary from "components/Button/IPIButtonPrimary";
import { useHistory, useParams} from "react-router-dom";
import Label from "components/Label/Label";
import Input from "components/Input/IPIInput";

export interface SidebarProps {
  className?: string;
}

const SubmissionHandler = (history: any, info: any) => {
  history.push({
    pathname:`/submissions/submit-post/${info}`,
    // state: { data: info }
  });
};

export const Sidebar: FC<SidebarProps> = ({ className = "space-y-6 " }) => {

  const [myUser, setMyUser] : any = useState(null);
  const [loadingUser, setLoadingUser] : any = useState(false);
  const [loading, setLoading] = useState(false)
  const [isAlertOn, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const history = useHistory();
  const params : any = useParams();
  // initialvalues field phone
  const [phone, setPhone] : any = useState('')

  const errorHandling = (msg : any) => {
    setErrorMsg(` ${JSON.stringify(msg)}`);
    setLoading(false);
    setAlert(true);
    setTimeout(()=> {setAlert(false);}, 10000)
  };


  const handleSubmit = (e : any) => {
    e.preventDefault();
    const data = new FormData();
    data.append('user_id', e.target.user_id.value);
    data.append('competition_id',  e.target.competition_id.value);
    data.append('instagram_account', e.target.instagram_account.value);
    if (e.target.child_name) {
      data.append('child_name', e.target.child_name.value);
    }
    data.append('phone', e.target.phone.value);
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
    },
    };

    axios.post(COMPETITIONSREGISTRATION_URL, data, options)
      .then((error) => {
        // const userdetail = userData.data;
        errorHandling('posting data berhasil')
        // localStorage.setItem(LOCALSTORAGE_USERDETAIL, JSON.stringify(userData.data));
        window.location.reload();
        // history.push({
        //   pathname: '/submissions/main',
        //   state: { data: listingAttendance },
        // });
        setLoading(false);
      })
      .catch((error) => {
        errorHandling(error.response.data)
        setLoading(false);
      }).catch((error) => {
        errorHandling(error.response.data)
      });
  }

  const [status, setStatus]: any = useState('No');
  const [loadingStatus, setLoadingStatus]: any = useState(false);

  const [competitionPost, setCompetitionPost]: any = useState([]);
  const [loadingPostCompetition, setLoadingPostCompetition]: any = useState(false);

  useEffect(() => {
    axios
      .get(`${COMPETITIONS_URL}${params.id}`)
      .then((res : any) => {
        setCompetitionPost(res.data);
        setLoadingPostCompetition(true);
      })
      .catch((error : any) => {
        console.log(error.response.data);
      });

    const localdata : any = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL) || 'null');
    setMyUser(localdata);
    if (localdata !== null) {
      setPhone(localdata.phone)
    }
    setLoadingUser(true);
    if(localdata !== null){
      const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
      const options = {
      headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
      };

      // 1. cek status register
      axios
        .get(`https://api.ibupembelajar.id/api-ipi/statuscompetitionbyiduser/${localdata.id}/idcompetition/${params.id}/`, options)
        .then((res : any) => {
            setStatus(res.data);
            setLoadingStatus(true);
        })
        .catch((error : any) => {
          console.log(error.response.data);
        });

        // 2. cek status submitted
        // axios
        // .get(`https://api.ibupembelajar.id/api-ipi/statussubmissioncompetitionbyiduser/${localdata.id}/idcompetition/${params.id}/`, options)
        // .then((res : any) => {
        //     setStatusSubmitted(res.data);
        //     setLoadingStatusSubmission(true);
        // })
        // .catch((error : any) => {
        //   console.log(error.response.data);
        // });
    }

  }, []);

  return (
    <div className={`nc-SingleSidebar ${className}`}>
      <div
        className={`nc-PageSubcription ${className}`}
        data-nc-id="PageSubcription"
      >
        <Alert color="primary">
          Halo, untuk mendaftar lomba ini mohon mengisi data berikut :
        </Alert>
        {isAlertOn && <Alert onClick={()=>setAlert(false) } color="danger">{ errorMsg}</Alert>}


      </div>
      {
        loadingStatus && (status.status === "Yes") ?
        (<ButtonPrimary onClick={() => SubmissionHandler(history, params.id)} className="md:col-span-2" type="submit">
          Unggah
        </ButtonPrimary>) :
        (
          loadingUser && myUser != null ?
            (
              <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
              <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
                <input
                  name="user_id"
                  type="hidden"
                  value={loadingUser && myUser.id}
                />
                <input
                  name="competition_id"
                  type="hidden"
                  value={params.id}
                />

                <label className="block md:col-span-2">
                  <Label>Name</Label>
                  <Input 
                    name="fullname"
                    className="mt-1"  
                    placeholder={myUser.fullname} 
                    value={myUser.fullname} 
                    disabled
                  />
                </label>

                <label className="block md:col-span-2">
                  <Label>Email</Label>
                  <Input  
                    className="mt-1"  
                    name="email"
                    placeholder={myUser.email} 
                    value={myUser.email}
                    disabled  
                  />
                </label>

                <label className="block md:col-span-2">
                  <Label>Telepon</Label>
                  <Input  
                    className="mt-1"  
                    name="phone"
                    placeholder={myUser.phone} 
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value)
                    }}
                    required
                  />
                </label>

                <label className="block md:col-span-2">
                  <Label>Akun Instagram</Label>
                  <Input 
                    className="mt-1"  
                    name="instagram_account"
                    placeholder="Instagram" 
                    required
                  />
                </label>

                {
                  loadingPostCompetition && competitionPost.with_child &&
                  <label className="block md:col-span-2">
                    <Label>Nama Anak</Label>
                    <Input 
                      name="child_name"
                      type="text" 
                      className="mt-1"  
                      placeholder="Nama Anak" 
                      required
                      />
                  </label>
                }

              {loading ? <div>loading...</div> : ''}
              <ButtonPrimary className="md:col-span-2" type="submit">
                Daftar Lomba
              </ButtonPrimary>
            </form>
            </div>
            ) : (
              <ButtonPrimary href="/login"> Login
              </ButtonPrimary>
            )
        )
      }

      {/* {
        loadingPost1 &&
        <WidgetCategories categories={postCategory1} />
      }
      {
        loading &&
        <WidgetTags tags={category} />
      }
      <WidgetAuthors authors={authors} />
      {
        loadingPost &&
        <WidgetPosts posts={post} />
      } */}
    </div>
  );
};
