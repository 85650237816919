import React, { FC, useEffect, useState } from "react";
import LayoutPage from "components/LayoutPage/IPILayoutPage";
import Input from "components/Input/IPIInput";
import ButtonPrimary from "components/Button/IPIButtonPrimary";
import NcLink from "components/NcLink/IPINcLink";
import { Helmet } from "react-helmet";
import axios from "axios";
import { PASSWORD_RESET_URL } from "utils/endpoint";
import { Alert } from "reactstrap";

export interface PageForgotPassProps {
  className?: string;
}

const PageForgotPass: FC<PageForgotPassProps> = ({ className = "" }) => {
  const [isAlertOn, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  const errorHandling = (msg: any) => {
    if (msg.status === 'OK') {
      setErrorMsg('Instruksi pengubahan kata sandi telah dikirimkan ke email Anda, silahkan periksa kotak masuk / SPAM');
    } else {
      setErrorMsg(` ${JSON.stringify(msg)}`);
    }
    setLoading(false);
    setAlert(true);
    setTimeout(()=> {setAlert(false);}, 10000)
  };

  const loginHandle = ( e: any) => {
    e.preventDefault();

    const data = new FormData();
    data.append('email', e.target.email.value);
    setLoading(true);
    axios.post(PASSWORD_RESET_URL, data)
      .then((res) => {
          errorHandling(res.data);
        }).catch((error) => {
          errorHandling(error.response.data);
        });
  }

  return (
    <div
      className={`nc-PageForgotPass ${className}`}
      data-nc-id="PageForgotPass"
    >
      <Helmet>
        <title>Lupa Kata Sandi</title>
      </Helmet>
      <LayoutPage
        subHeading="Kami akan mengirimkan instruksi ganti kata sandi ke email Anda"
        headingEmoji="🔐"
        heading="Lupa Kata Sandi"
      >
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={loginHandle}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Alamat Email
              </span>
              <Input
                type="email"
                name="email"
                placeholder="example@example.com"
                className="mt-1"
                required
              />
            </label>
            {isAlertOn && <Alert onClick={() => setAlert(false)} type="danger">{errorMsg}</Alert>}
            <div className="flex items-center justify-center">
              {isLoading && <div>loading...</div>}
            </div>
            <ButtonPrimary type="submit">
              Kirim
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Kembali ke {` `}
            <NcLink to="/login">Masuk</NcLink>
            {` / `}
            <NcLink to="/signup">Daftar</NcLink>
          </span>
        </div>
      </LayoutPage>
    </div>
  );
};

export default PageForgotPass;
