export const BASE_URL = 'https://api.ibupembelajar.id';
// export const BASE_URL = 'http://ec2-13-251-43-182.ap-southeast-1.compute.amazonaws.com';
export const API_URL = `${BASE_URL}/api-ipi`;

//AUTH
export const AUTH_URL_LOGIN = `${BASE_URL}/auth-ipi/token/login/`;
export const AUTH_URL_USERME = `${BASE_URL}/auth-ipi/users/me/`;
export const AUTH_URL_SIGNUP = `${BASE_URL}/auth-ipi/users/`

// get token signin with google
export const GOOGLE_TOKEN_LOGIN = `${API_URL}/google-token-login/`

// Category
export const POSTCATEGORY_URL = `${API_URL}/postcategory/`
export const POSTCATEGORY1_URL = `${API_URL}/postsubcategory1/`
export const POSTCATEGORY2_URL = `${API_URL}/postsubcategory2/`

// post 
export const POST_URL = `${API_URL}/post/`
export const POSTUSER_URL = `${API_URL}/dashboard-post/`
export const POST_LIKE = `${API_URL}/postlike/`
export const POST_AUTHOR = `${API_URL}/authorpost/`
export const POST_BYIDCATEGORY_URL = `${API_URL}/postbyidcategory/`
export const ALLPOST_URL = `${API_URL}/all-post/`

// homepage post
export const HOMEPAGEPOST_URL = `${API_URL}/homepagepost/`


//events
export const ADMIN_EVENT_URL = `${API_URL}/adminevent/`
export const EVENT_URL = `${API_URL}/event/`
export const HOMEEVENT_URL = `${API_URL}/homepageevent/` 
export const ORDER_URL = `${API_URL}/order/`
export const ORDERUSER_URL = `${API_URL}/dashboard-orderuser/`
export const EVENTREGISTRATION_URL = `${API_URL}/eventregistration/`
export const EVENTUSER_URL = `${API_URL}/dashboard-eventregistration/`
export const SURVEYQUESTION_EVENT_URL = `${API_URL}/surveyevent/`
export const SURVEYANSWER_EVENT_URL = `${API_URL}/answersurvey/`
export const CEKANSWEREVENTUSER_URL = `${API_URL}/statusansweruserbyidevent/`

// competitions
export const COMPETITIONS_URL = `${API_URL}/postcompetition/`
export const COMPETITIONSREGISTRATION_URL = `${API_URL}/competitionregistration/`
export const COMPETITIONSUSER_URL = `${API_URL}/dashboard-competitionregistration/`
export const COMPETITIONSMEMBERS_URL = `${API_URL}/competitionmember/`
export const COMPETITIONSMEMBERSTYPE_URL = `${API_URL}/competitionmemberbytypecompetition/`

// homepage post competition
export const HOMEPAGECOMPETITIONS_URL = `${API_URL}/homepagepostcompetition/`

// library
export const LIBRARY_URL = `${API_URL}/library/`

// partner
export const PARTNERS_LIST_URL = `${API_URL}/ourpartner/`
// partner
export const ADVERTISEMENT_URL = `${API_URL}/advertisement/`

// newsletter
export const NEWSLETTER_URL = `${API_URL}/newsletter/`

// blastemail
export const BLASTEMAIL_URL = `${API_URL}/blastemail/`
export const IMAGEGALLERY_URL = `${API_URL}/imagegallery/`

// course
export const HOMECOURSE_URL = `${API_URL}/homepagecourse/` 

// course level
export const HOMECOURSELEVELBYCOURSE_URL = `${API_URL}/homepagelevelbyidcourse/` 
export const HOMECOURSELEVEL_URL = `${API_URL}/homepagelevel/` 

// course schedule
export const HOMECOURSESCHEDULE_URL = `${API_URL}/homepageschedulebyidlevel/` 

// enrolled course
export const ENROLLEDCOURSE_URL = `${API_URL}/enrolledcourse/` 

// password reset
export const PASSWORD_RESET_URL = `${API_URL}/password_reset/`
export const PASSWORD_RESET_CONFIRM_URL = `${API_URL}/password_reset/confirm/`
