import LayoutPage from "components/LayoutPage/IPILayoutPage";
import { FC, useState, useEffect } from "react";
import Input from "components/Input/IPIInput";
import ButtonPrimary from "components/Button/IPIButtonPrimary";
import NcLink from "components/NcLink/IPINcLink";
import { Helmet } from "react-helmet";
import axios from 'axios';
import {
  Alert } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import {AUTH_URL_LOGIN, AUTH_URL_USERME, GOOGLE_TOKEN_LOGIN} from "../../utils/endpoint";
import {GoogleLogin} from 'react-google-login';
import {
  LOCALSTORAGE_TOKEN,
  LOCALSTORAGE_USERDETAIL,
} from '../../utils/Types';

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {

  const history: any = useHistory();

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  const [isAlertOn, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setLoading] = useState(false);
  const params: any = useParams();


  const errorHandling = (msg: any) => {
    setErrorMsg(` ${JSON.stringify(msg)}`);
    setLoading(false);
    setAlert(true);
    setTimeout(()=> {setAlert(false);}, 10000)
  };

  const clientId = '872999947193-50u5c93ok73hk8h85ufh9sg043e0hnnt.apps.googleusercontent.com';

  const onSuccessGoogle = (e: any) => {
    const { profileObj } = e;
    const { vc } = e;
    const data = new FormData();
    data.append('email', profileObj.email);
    data.append('name', profileObj.name);
    data.append('imageUrl', profileObj.imageUrl);
    data.append('google_id', profileObj.googleId)
    data.append('token_type', vc.token_type);
    data.append('access_token', vc.access_token)
    axios.post(GOOGLE_TOKEN_LOGIN, data)
      .then((res) => {
        const token = res.data.auth_token;
        if(token){
          // localStorage.setItem("LOCALSTORAGE_TOKEN", token);
          localStorage.setItem(LOCALSTORAGE_TOKEN, token);

           // authorization
          axios.get(AUTH_URL_USERME, { headers: { Authorization: `Token ${token}` } }).then((userData: any) => {
            // const userdetail = userData.data;
            localStorage.setItem(LOCALSTORAGE_USERDETAIL, JSON.stringify(userData.data));
            if(params.url){
              errorHandling('Login Berhasil');
              const args =  params.url.split('-');
              history.push(`/${args[0]}/${args[1]}/`)
              window.location.reload()
            }else{
              errorHandling('Login Berhasil');
              history.push('/');
              window.location.reload()
            }
            setLoading(false);
          })
        }
      })
      .catch((err) => {
        console.log('ERROR CATCH', err.response.data)
      })
    history.push('/');
  }

  const onfailedGoogle = (e: any) => {
    console.log('Failed in Login with Google', e);
  }

  const loginHandle = ( e: any) => {
    e.preventDefault();

    const data = new FormData();
    data.append('email', e.target[0].value);
    data.append('password', e.target[1].value);
    setLoading(true);
    axios.post(AUTH_URL_LOGIN, data)
      .then((res) => {
        // authentication
        const token = res.data.auth_token;
        if(token){
          // localStorage.setItem("LOCALSTORAGE_TOKEN", token);
          localStorage.setItem(LOCALSTORAGE_TOKEN, token);

           // authorization
          axios.get(AUTH_URL_USERME, { headers: { Authorization: `Token ${token}` } }).then((userData: any) => {
            // const userdetail = userData.data;
            localStorage.setItem(LOCALSTORAGE_USERDETAIL, JSON.stringify(userData.data));
            if(history.location.state){
              errorHandling('Login Berhasil');
              history.push(history.location.state)
              window.location.reload()
            }else{
              errorHandling('Login Berhasil');
              history.push('/');
              window.location.reload()
            }
            setLoading(false);

          })
        }
        }).catch((error) => {
          errorHandling(error.response.data);
        });

  }
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Masuk</title>
      </Helmet>
      <LayoutPage
        subHeading="Selamat Datang di Komunitas Ibu Pembelajar Indonesia"
        headingEmoji="🔑"
        heading="Masuk"
      >
        <div className="max-w-md mx-auto space-y-6">
          {/* <div className="grid gap-3">
            <GoogleLogin
              clientId = {clientId}
              buttonText="Login menggunakan Google"
              onSuccess={onSuccessGoogle}
              onFailure={onfailedGoogle}
              cookiePolicy={'single_host_origin'}
              style={{marginTop: '100px'}}
              // isSignedIn={true}
            />
          </div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              atau
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={loginHandle}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Alamat Email
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Kata Sandi
                <NcLink to="/forgot-pass" className="text-sm">
                  Lupa Kata Sandi?
                </NcLink>
              </span>
              <Input type="password" placeholder="Masukan kata sandi" className="mt-1" />
            </label>
            {isAlertOn && <Alert onClick={() => setAlert(false)} type="danger">{errorMsg}</Alert>}
            <div className="flex items-center justify-center">
              {isLoading && <div>loading...</div>}
            </div>
            <ButtonPrimary type="submit">
              Masuk
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Belum punya akun? {` `}
            {/* <NcLink to="/signup">Buat Akun</NcLink> */}
            {history.location.state ? (
              <>
                <NcLink to={{
                  pathname: '/signup',
                  state: history.location.state 
                }}>Buat Akun</NcLink>
              </>
            ) : (
              <NcLink to="/signup">Buat Akun</NcLink>
            )}
          </span>
        </div>
      </LayoutPage>
    </div>
  );
};

export default PageLogin;
