import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/IPIBgGlassmorphism";
import SectionHero from "components/SectionHero/IPISectionHero";
import SectionSliderEvents from "./SectionEvent/IPISectionSliderEvents";
import SectionCourse from "./SectionCourse/IPISectionCourse";
import SectionSliderCompetitions from "./SectionCompetition/IPISectionSliderCompetitions";
// import rightImg from "images/hero-right.png";
import SectionSubscribe2 from "components/SectionSubscribe2/IPISectionSubscribe2";
import BackgroundSection from "components/BackgroundSection/IPIBackgroundSection";
import Partnership from "containers/PageHome/SectionPartners/Partnership";
import axios from "axios";
import { PARTNERS_LIST_URL } from "../../utils/endpoint";
import Article from "./SectionPosts/IPISectionHomepageArtikel";

const PageHomeIPI: React.FC = () => {
  const [dataPartners, setDataPartners]: any = useState([]);
  const [iklan, setIklan]: any = useState([]);

  useEffect(() => {
    axios
      .get(PARTNERS_LIST_URL)
      .then((res: any) => {
        setDataPartners(res.data);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
    axios
      .get('https://api.ibupembelajar.id/api-ipi/availableadvertisement/')
      .then((res: any) => {
        setIklan(res.data);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });

    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <div className="nc-PageHomeIPI overflow-hidden relative">
      <Helmet>
        <title>Beranda</title>
      </Helmet>
      <BgGlassmorphism />
      <div className="container relative">
        <SectionHero
          rightImg={iklan.length > 0 ? iklan: []}
          className="pt-10 pb-16 md:py-16 lg:py-28"
          heading={
            <span>
              Ibu Pembelajar <br /> Indonesia {` `}
            </span>
          }
          btnText="Daftar Sekarang"
          subHeading="Komunitas Ibu dan Calon Ibu, yang selalu mengembangkan kualitas diri dan keluarga, dengan pengetahuan yang bermanfaat."
        />

        <SectionCourse
          className="py-16 lg:py-28"
          heading="Pendaftaran Kelas Bulan Bahasa Ibu"
          subHeading="Meningkatkan kemampuan dan keterampilan ibu"
        />

        <SectionSliderEvents
          className="py-16 lg:py-28"
          heading="Kegiatan dan Webinar"
          subHeading="Membangun kualitas diri dan keluarga yang bahagia"
        />

        <SectionSliderCompetitions
          className="py-16 lg:py-28"
          heading="Kompetisi"
          subHeading="Membangun kualitas diri dan keluarga yang bahagia"
        />

        <div className="relative py-16">
          <BackgroundSection />
          <Article heading="Artikel Pilihan Kami" />
        </div>

        <Partnership
          headingCenter={false}
          categoryCardType="card2"
          className="pb-16 lg:pb-28"
          categories={dataPartners}
        />
        <SectionSubscribe2 className="pb-16 lg:pb-28 pt-16" />
      </div>
    </div>
  );
};

export default PageHomeIPI;
