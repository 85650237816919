import React, { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import { Sidebar } from "./Sidebar";
import axios from "axios";
import { useParams } from "react-router-dom";
import { HOMECOURSE_URL } from "../../../../utils/endpoint";
import NcImage from "components/NcImage/IPINcImage";
import SectionAds from "containers/PageHome/IPISectionAds";

const PageCourseDetail = () => {
  const params: any = useParams();

  const [course, setCourse]: any = useState([]);
  const [loading, setLoading]: any = useState(false);

  useEffect(() => {
    axios
      .get(`${HOMECOURSE_URL}${params.id}/`)
      .then((res: any) => {
        setCourse(res.data);
        setLoading(true);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
  }, []);

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <>
      <div
        className={`nc-PageSingleTemp3Sidebarpt-16 lg:pt-24`}
        data-nc-id="PageSingleTemp3Sidebar"
      >
        {/* <SectionAds className=" sm: pt-8" /> <br></br>
        <br></br> */}
        <div className="container rounded-xl w-full">
          <div className={`nc-SingleHeader`}>
            <div className="space-y-5">
              <h1
                className={
                  "text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-5xl dark:text-neutral-100 max-w-4xl "
                }
                title={course.title}
              >
                {course.title}
              </h1>
              <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
            </div>
          </div>
        </div>
        <div className="container my-10">
          <div className="lg:flex md:flex container gap-8">
            <NcImage
              containerClassName="my-10 sm:my-12"
              className="object-cover mr-2 w-full content-center items-center rounded-xl"
              src={course.thumbnail}
            />
            <NcImage
              containerClassName="my-10 sm:my-12"
              className="object-cover mr-2 w-full content-center items-center rounded-xl"
              src={course.image_2}
            />
          </div>

          <div className="container ">
            {loading && (
              <>
                <div className="w-full mt-12 lg:mt-0 lg:w-full lg:pl-10 xl:pl-0 xl:w-full">
                  <Sidebar />
                </div>
              </>
            )}
          </div>
        </div>
        {/* RELATED POSTS
        <SingleRelatedPosts /> */}
      </div>
    </>
  );
};

export default PageCourseDetail;
