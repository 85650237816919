import React, { FC } from "react";
import Avatar from "components/Avatar/Avatar";
import moment from "moment";

export interface PostCardMetaProps {
  className?: string;
  meta: any;
  hiddenAvatar?: boolean;
  size?: "large" | "normal";
}

const PostCardMeta: FC<PostCardMetaProps> = ({
  className = "leading-none",
  meta,
  // hiddenAvatar = false,
  size = "normal",
}) => {
  const { created_at, author_detail } = meta;

  return (
    <div
      className={`nc-PostCardMeta inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 ${
        size === "normal" ? "text-xs" : "text-base"
      } ${className}`}
      data-nc-id="PostCardMeta"
    >
      { author_detail !== undefined && 
        <div className="relative flex items-center space-x-2">
          {/* {!hiddenAvatar && ( */}
            <Avatar
              radius="rounded-full"
              sizeClass={
                size === "normal" ? "h-7 w-7 text-sm" : "h-10 w-10 text-xl"
              }
              imgUrl={author_detail?.photo}
              userName={author_detail?.fullname}
            />
          {/* )} */}
          <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
            {author_detail?.fullname}
          </span>
        </div>
      }
      <>
        <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
          ·
        </span>
        <span className="text-neutral-500 dark:text-neutral-400 font-normal"> 
          {moment(created_at, 'YYYY-MM-DD | HH:mm:ss').format('DD-MM-YYYY | HH:mm')}
        </span>
      </>
    </div>
  );
};

export default PostCardMeta;
