import Heading from "components/Heading/IPIHeading";
import PartnershipM from "./IPIPartneshipM";
// import PartnershipS from "./IPIPartnershipS";
import React from "react";

export interface SectionGridCategoryBoxProps {
  categories?: any;
  headingCenter?: boolean;
  categoryCardType?: "card2";
  className?: string;
}

const Partnership: React.FC<SectionGridCategoryBoxProps> = ({
  categories,
  headingCenter = true,
  className = "",
}) => {
 
    return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <Heading desc="" isCenter={headingCenter}>
        Partner Kami
      </Heading>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 sm:gap-6 md:gap-8">
        {categories.map((item:any) => (
          <PartnershipM
            index={item.id < 3 ? `#${item.id + 1}` : undefined}
            key={item.id}
            taxonomy={item}
          />
        ))}
      </div>
      {/* <div className="pb-8 lg:pb-14"/>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 sm:gap-6 md:gap-8">
        {categories.map((item:any) => (
          <PartnershipS
            index={item.id < 3 ? `#${item.id + 1}` : undefined}
            key={item.id}
            taxonomy={item}
          />
        ))}
      </div> */}
    </div>
  );
};

export default Partnership;
