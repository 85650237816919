import React, { FC, useRef } from "react";
import NcImage from "components/NcImage/IPINcImage";
import GallerySlider from "./IPIGallerySlider";

export interface PostFeaturedMediaProps {
  className?: string;
  post: any;
  isHover?: boolean;
}
const PostFeaturedMedia: FC<PostFeaturedMediaProps> = ({
  className = " w-full h-full ",
  post,
  isHover = false,
}) => {
  const { image, postType, galleryImgs } = post;

  const videoRef = useRef(null);
  const renderGallerySlider = () => {
    if (!galleryImgs) return null;
    return <GallerySlider galleryImgs={galleryImgs} />;
  };

  const renderContent = () => {
    // GALLERY
    if (postType === "gallery") {
      return renderGallerySlider();
    }
  };

  return (
    <div
      className={`nc-PostFeaturedMedia relative ${className}`}
      data-nc-id="PostFeaturedMedia"
      ref={videoRef}
    >
      <NcImage containerClassName="absolute inset-0" src={image} />
      {renderContent()}
    </div>
  );
};

export default PostFeaturedMedia;
