/* eslint-disable react/jsx-no-target-blank */
import React, { FC } from "react";
import imgAdsDef from "images/ipipsi.png";

export interface SectionPsycologyProps {
  className?: string;
  imgAds?: string;
  imgAds2?: string;
}

const SectionPsycology: FC<SectionPsycologyProps> = ({
  className = "",
  imgAds = imgAdsDef,
}) => {
//   const [toggle, setToogle] = useState(false);

//   const timeOutHandler = () => {
//     setToogle(!toggle);
//   }

//   useEffect(() => {
//     setInterval(() => {
//       timeOutHandler();
//     }, 4000);
//   }, []);

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    // <div>
      <a
        href="https://exam.educourse.id/psychology"
        // href="https://educourse.id/course/detail/?id=35-TPBM"
        target="_blank"
        className={`nc-SectionAds block text-center mx-auto ${className}`}
        id="adsIpi"
      >
        {/* <span className="text-xs text-neutral-500">- Sponsorship -</span> */}
        <img className="mx-auto" src={imgAds} alt="" />
      </a>
    // </div>
  );
};

export default SectionPsycology;
