import Logo from "components/Logo/Logo";
import SocialsList1 from "components/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Tautan langsung",
    menus: [
      { href: "/about", label: "Sejarah IPI" },
      { href: "/about", label: "Visi & Misi" },
      { href: "/about", label: "Program Kerja" },
      { href: "/contact", label: "Kontak" },
      // { href: "#", label: "Editor Support" },
      // { href: "#", label: "Utility-First" },
      // { href: "#", label: "Dark Mode" },
      // { href: "#", label: "Responsive Design" },
    ],
  },
  // {
  //   id: "1",
  //   title: "Artikel",
  //   menus: [
  //     { href: "/articles/:id", label: "Ibu" },
  //     { href: "/articles/:id", label: "Anak" },
  //     { href: "/articles/:id", label: "Lainnya" },
  //     { href: "#", label: "Pricing" },
  //     { href: "#", label: "Customers" },
  //     { href: "#", label: "Security" },
  //     { href: "#", label: "Integrations" },
  //     { href: "#", label: "Contact" },
  //   ],
  // },
  // {
  //   id: "2",
  //   title: "Resources",
  //   menus: [
  //     { href: "#", label: "Best practices" },
  //     { href: "#", label: "Support" },
  //     { href: "#", label: "Developers" },
  //     { href: "#", label: "Learn design" },
  //     { href: "#", label: "What's new" },
  //     { href: "#", label: "Releases" },
  //     { href: "#", label: "Careers" },
  //     { href: "#", label: "About us" },
  //   ],
  // },
  // {
  //   id: "4",
  //   title: "Komunitas & Members",
  //   menus: [
  //     { href: "#", label: "Discussion Forums" },
  //     { href: "#", label: "Point on Check" },
  //     { href: "#", label: "Community Resources" },
  //     // { href: "#", label: "Contributing" },
  //     // { href: "#", label: "Concurrent Mode" },
  //     // { href: "#", label: "API Reference" },
  //     // { href: "#", label: "Advanced Guides" },
  //     // { href: "#", label: "Main Concepts" },
  //   ],
  // },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm ">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4 ">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-16 lg:py-28 border-t border-neutral-200 dark:border-neutral-700 ">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-3 lg:gap-x-10 ">
      {/* <div className="container grid grid-cols-3 gap-y-10 gap-x-6  "> */}
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col sm:col-span-2">
          <div className="col-span-2  space-y-3">
            <Logo />
            <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
            Ibu Pembelajar Indonesia
            </h2>
            <p>
            Komunitas untuk seluruh Ibu-ibu di Indonesia yang memiliki semangat untuk terus bertumbuh dan berkembang bersama
            </p>
            <div className="col-span-2 flex items-center md:col-span-3">
              <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
            </div>
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;
