import React, { FC, useEffect, useState } from "react";
import NcImage from "components/NcImage/IPINcImage";
import { LOCALSTORAGE_USERDETAIL } from "utils/Types";
export interface PageEBookProps {
  className?: string;
  post: any;
}

const PageEBook: FC<PageEBookProps> = ({
  className = "h-full",
  post,
}) => {
  const [myUser, setMyUser] : any = useState(null);
  const { title, image, file } = post;
  useEffect(() => {
    const localdata : any = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL) || 'null');
    setMyUser(localdata);
  }, []);
  return (
    <div
      className={`nc-PageEBook relative flex group items-center p-3 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="PageEBook"
    >
      <div className="w-1/4 flex-shrink-0">
        <div
          className={`block h-0 aspect-w-1 aspect-h-1 relative rounded-full overflow-hidden shadow-lg `}
        >
          <NcImage
            containerClassName="absolute inset-0"
            className="object-cover w-full h-full "
            src={image}
            alt={title}
          />
        </div>
      </div>

      <div className="flex flex-col flex-grow ml-4">
        <h2 className={`nc-card-title block font-semibold text-lg`}>
          <div
            title={title}
          >
            {title}
          </div>
        </h2>
        <span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1 ">
          {
            myUser !== null &&
            <a href={file} target="blank">Download</a>
          }
        </span>
      </div>
    </div>
  );
};

export default PageEBook;
