import React, { FC, useState, useEffect } from "react";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import { Helmet } from "react-helmet";
import Card11 from "./Card11";
import axios from "axios";
import {POSTCATEGORY_URL, POST_URL} from "../../utils/endpoint"

export interface PageSearchV2Props {
  className?: string;
}

const PagePostArticle: FC<PageSearchV2Props> = ({ className = "" }) => {

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  const [category, setCategory]: any = useState([]);
  const [loading, setLoading]: any = useState(false);


  const [posts, setPosts]: any = useState([]);
  const [loadingPost, setLoadingPost]: any = useState(false);

  const [filterProduct, setFilterProduct] : any = useState(posts)
  const [filter, setFilter] : any = useState('')

  const [tabActive, setTabActive] = useState<string>("Semua");


  const FilteredProduct = (data: any) => {
    if (data === tabActive) {
      return;
    }
    setTabActive(data);
    setFilter(data)
    const updateProduct = posts.filter((dataNow: any)=>{
      return dataNow.subcategory2_detail.subcategory1_detail.category_detail.name === data;
  })
    setFilterProduct(updateProduct)
  }
  useEffect(() => {
    axios
      .get(POSTCATEGORY_URL)
      .then((res : any) => {
          setCategory(res.data);
          setLoading(true);
      })
      .catch((error : any) => {
        console.log(error.response.data);
      });

      axios
      .get(POST_URL)
      .then((res : any) => {
          setPosts(res.data);
          setLoadingPost(true);
      })
      .catch((error : any) => {
        console.log(error.response.data);
      });
  }, []);

  return (
    <>
    <div className={`nc-PageSearchV2 ${className}`} data-nc-id="PageSearchV2">
      {/* <HeadBackgroundCommon className="h-24 2xl:h-28" /> */}
      <Helmet>
        <title>Artikel</title>
      </Helmet>
      {/* <div className="container">
        <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
          <form className="relative" action="" method="post">
            <label
              htmlFor="search-input"
              className="text-neutral-500 dark:text-neutral-300"
            >
              <span className="sr-only">Search all icons</span>
              <Input
                id="search-input"
                type="search"
                placeholder="Type and press enter"
                className="shadow-lg rounded-xl border-opacity-0"
                sizeClass="pl-14 py-5 pr-5 md:pl-16"
              />
              <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                  ></path>
                </svg>
              </span>
            </label>
          </form>
          <span className="block text-sm mt-4 text-neutral-500 dark:text-neutral-300">
            We found{" "}
            <strong className="font-semibold text-neutral-800 dark:text-neutral-100">
              1135
            </strong>{" "}
            results articles for{" "}
            <strong className="font-semibold text-neutral-800 dark:text-neutral-100">
              "dddd"
            </strong>
          </span>
        </header>
      </div> */}
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <main>
          {/* TABS FILTER */}
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row ">
            <Nav
              containerClassName="w-full overflow-x-auto hiddenScrollbar"
              className=" sm:space-x-2"
            >
              <NavItem
                isActive={tabActive === "Semua"}
                onClick={() => FilteredProduct("Semua")}
              >
                Semua
              </NavItem>
              {loading && category.map((item : any) => (
                <NavItem
                  key={item.id}
                  isActive={tabActive === item.name}
                  onClick={() => FilteredProduct(item.name)}
                >
                  {item.name}
                </NavItem>
              ))}
            </Nav>
          </div>

          {/* LOOP ITEMS */}
          {loadingPost && loading && tabActive === "Semua" && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:gap-8 mt-8 lg:mt-10">
              {posts.map((post : any) => (
                <Card11 key={post.id} post={post} />
              ))}
            </div>
          )}
          {loadingPost && loading && tabActive === filter && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:gap-8 mt-8 lg:mt-10">
              {filterProduct.map((post : any) => (
                <Card11 key={post.id} post={post} />
              ))}
            </div>
          )}
        </main>
      </div>
    </div>
    </>
  );
};

export default PagePostArticle;
