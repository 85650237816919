import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "components/Footer/Footer";
import Page404 from "containers/Page404/Page404";

// account
import PageContact from "containers/PageContact/PageContact";
import PageLogin from "containers/PageLogin/IPIPageLogin";
import PageSignUp from "containers/PageSignUp/IPIPageSignUp";
import PageForgotPass from "containers/PageForgotPass/PageForgotPass";
import PageChangePass from "containers/PageChangePass/PageChangePass";

// homepage
import PageHomeIPI from "containers/PageHome/PageHomeIPI";

// about
import PageAbout from "containers/PageAbout/PageAbout";

// event
import PageEvent from "containers/PageEvent/PageEvent";
import PageEventDetail from "containers/PageEvent/EventDetails/PageEventDetail";

// artikel
import PagePostArticle from "containers/PagePost/PagePostArticle";
import PageDetailPost from "containers/PagePost/PageDetailPost/PageDetailPost";

// competitions
import PageCompetitions from "containers/PageCompetition/CompetitionPage/CompetitionPage";
import PageCompetitionsDetail from "containers/PageCompetition/CompetitionDetailPage/CompetitionDetail";
import PageSubmissionCompetitions from "containers/PageCompetition/PageSubmissionCompetitions/PageDashboard";
import ParticipantProjectPage from "containers/PageCompetition/ParticipantProject/ParticipantProjectPage";

// library
import PageLibrary from "containers/PageLibrary/PageLibrary";

// dashboard user
import PageDashboard from "containers/PageDashboardUser/PageDashboard";
// Post article
import DashboardSubmitPost from "containers/PageDashboardUser/PagePost/DashboardSubmitPost";
import DashboardEditPost from "containers/PageDashboardUser/PagePost/DashboardEditPost";
// Event 
import DetailEventUser from "containers/PageDashboardUser/PageDashboardEvent/DetailEventUser/DetailEventUser";
// competitions
import MySubmissionCompetition from "containers/PageDashboardUser/PageDashboardCompetition/MySubmission/PageDashboard"

import HeaderContainer from "containers/HeaderContainer/HeaderContainer";

import PageSuccess from "containers/PageSuccess/PageSuccess";
import PageCourseDetail from "containers/Homepage/PageCourse1/CourseDetails/PageCourseDetail";
import PageHomeCourse from "containers/Homepage/PageCourse/PageCourse";


export const pages: Page[] = [
  // Account
  { path: "/login", component: PageLogin },
  { path: "/signup", component: PageSignUp },
  { path: "/forgot-pass", component: PageForgotPass },
  { path: "/change-pass/:token", component: PageChangePass },
  { path: "/contact", component: PageContact },

  // homepage
  { path: "/", exact: true, component: PageHomeIPI },
  { path: "/#", exact: true, component: PageHomeIPI },
  { path: "/page404", component: Page404 },
  // about
  { path: "/about", component: PageAbout },
  // event
  { path: "/events", component: PageEvent},
  { path: "/event/:id", component: PageEventDetail},
  // article
  { path: "/articles", component: PagePostArticle },
  { path: "/article/:id", component: PageDetailPost },

  // course
  { path: "/course/:id", component: PageCourseDetail },
  
  // course inu pembelajar
  { path: "/bulanbahasa", component: PageHomeCourse },



  // competition
  { path: "/competitions", component: PageCompetitions },
  { path: "/competition/:id", component:  PageCompetitionsDetail },
  { path: "/submissions", component: PageSubmissionCompetitions },
  { path: "/participant-project", component: ParticipantProjectPage },
  
  // library
  { path: "/library", component: PageLibrary },

  // dashboard user
  { path: "/dashboard", component: PageDashboard },
  { path: "/checkout/successpage", component: PageSuccess },
  // Dashboard/user/artikel
  { path: "/new-article", component:  DashboardSubmitPost },
  { path: "/edit-article/:id", component:  DashboardEditPost },
  // Dashboard/user/kegiata&webinar
  { path: "/event-user/detail/:id", component: DetailEventUser},
  // Dashboard/user/competitions
  { path: "/my-submission", component: MySubmissionCompetition},

];

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <HeaderContainer />
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
      {/* MEDIA */}
    </BrowserRouter>
  );
};

export default Routes;
