import React, { FC } from "react";
import NcImage from "components/NcImage/IPINcImage";

export interface CardCategory2Props {
  className?: string;
  taxonomy: any;
  index?: string;
}

const PartnershipM: FC<CardCategory2Props> = ({
  className = "",
  taxonomy,
  index,
}) => {
  const { image, website, name } = taxonomy;
  return (
    <a
      href={website}
      className={`nc-CardCategory2 relative flex flex-col items-center justify-center text-center px-3 py-5 sm:p-6  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className}`}
      data-nc-id="CardCategory2"
    >
      <NcImage
        containerClassName={`flex-shrink-0 w-30 h-30 overflow-hidden`}
        src={image}
      />
      <div className="mt-3 ">
        <h2 className={`text-base sm:text-lg font-semibold `}>
          <span className="line-clamp-1">{name}</span>
        </h2>
      </div>
    </a>
  );
};

export default PartnershipM;
