import React, { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import { Sidebar } from "./Sidebar";
import axios from "axios";
import { useParams } from "react-router-dom";
import { EVENT_URL } from "../../../utils/endpoint";
import NcImage from "components/NcImage/IPINcImage";
import SectionAds from "containers/PageHome/IPISectionAds";

const PageEventDetail = () => {
  const params: any = useParams();

  const [event, setEvent]: any = useState([]);
  const [loading, setLoading]: any = useState(false);

  useEffect(() => {
    axios
      .get(`${EVENT_URL}${params.id}/`)
      .then((res: any) => {
        setEvent(res.data);
        setLoading(true);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
  }, []);

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <>
      <div
        className={`nc-PageSingleTemp3Sidebarpt-16 lg:pt-24`}
        data-nc-id="PageSingleTemp3Sidebar"
      >
        <SectionAds className=" sm: pt-8" /> <br></br>
        <br></br>
        <div className="container rounded-xl w-full">
          <div className={`nc-SingleHeader`}>
            <div className="space-y-5">
              <h1
                className={
                  "text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-5xl dark:text-neutral-100 max-w-4xl "
                }
                title={event.title}
              >
                {event.title}
              </h1>
              <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
            </div>
          </div>
        </div>
        <NcImage
          containerClassName="container my-10 sm:my-12"
          className="object-cover content-center items-center w-full rounded-xl"
          src={event.image}
        />
        <div className="container flex flex-col my-10 lg:flex-row ">
          {loading && (
            <>
              <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-20">
                <div className="nc-SingleContent space-y-10">
                  <div
                    id="single-entry-content"
                    className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
                  >
                    <div>{HtmlParser(event.description)}</div>
                  </div>
                </div>
              </div>
              <div className="w-full mt-12 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3">
                <Sidebar />
              </div>
            </>
          )}
        </div>
        {/* RELATED POSTS
        <SingleRelatedPosts /> */}
      </div>
    </>
  );
};

export default PageEventDetail;
