import { useEffect, useState } from "react";
import NcImage from "components/NcImage/IPINcImage";
import axios from "axios";
import { POSTUSER_URL } from "../../../utils/endpoint";
import { LOCALSTORAGE_TOKEN } from "../../../utils/Types";
import ButtonPrimary from "components/Button/IPIButtonPrimary";
import { useHistory } from "react-router-dom";
import ButtonEdit from "components/Button/ButtonEdit";
import { MDBDataTable } from "mdbreact";

const EditHandler = (history: any, info: any) => {
  history.push({
    pathname: `/edit-article/${info.id}`,
    state: { data: info },
  });
};

const DashboardPosts = () => {
  const history = useHistory();
  const [posts, setPost]: any = useState([]);

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(POSTUSER_URL, options)
      .then((res: any) => {
        setPost(res.data);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
  }, []);

  const data = {
    columns: [
      {
        label: "Index",
        field: "index",
        sort: "asc",
        width: 20,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 20,
      },
      {
        label: "Judul",
        field: "event",
        sort: "asc",
        width: 20,
      },
      {
        label: "Disukai",
        field: "date",
        sort: "asc",
        width: 20,
      },
      {
        label: "Terbit",
        field: "type",
        sort: "asc",
        width: 20,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 20,
      },
    ],
    rows: posts.map((e: any, index: any) => ({
      index: `${index + 1}`,
      image: [
        <NcImage
          containerClassName="flex-shrink-0 h-12 w-12 rounded-lg overflow-hidden lg:h-14 lg:w-14"
          src={e.image}
        />,
      ],
      event: e.title,
      date: e.like_detail.length,
      type: [
        <div>
          {e.is_publish === true ? <span> ✅ </span> : <span> ❎ </span>}
        </div>,
      ],
      action: [
        <div>
          <ButtonEdit onClick={() => EditHandler(history, e)}>
            Detail
          </ButtonEdit>
          {` | `}
          <button
            className="text-rose-600 hover:text-rose-900"
            onClick={(e: any) => {
              e.preventDefault();
              const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
              const options = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Token ${token}`,
                },
              };
              axios
                .delete(`${POSTUSER_URL}${e.id}/`, options)
                .then(() => {
                  window.location.reload();
                  // history.push({
                  //   pathname: '/admin/competitions',
                  // });
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            Hapus
          </button>
        </div>,
      ],
    })),
  };
  return (
    <div className="flex flex-col space-y-8">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full px-1 sm:px-6 lg:px-8">
          <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
            <div className="px-3 py-3">
              <ButtonPrimary href="/new-article">
                ➕ Tambah Artikel
              </ButtonPrimary>
            </div>
            <MDBDataTable
              noBottomColumns={true}
              theadTextWhite={false}
              responsive
              bordered
              data={data}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPosts;
