import React, { FC, useState, useEffect } from "react";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import { Helmet } from "react-helmet";
import Card11 from "./Card11";
import axios from "axios";
import {HOMECOURSE_URL, POSTCATEGORY_URL, POST_URL} from "../../../utils/endpoint"

export interface PageSearchV2Props {
  className?: string;
}

const PagePostArticle: FC<PageSearchV2Props> = ({ className = "" }) => {

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  const [category, setCategory]: any = useState([]);
  const [loading, setLoading]: any = useState(false);


  const [posts, setPosts]: any = useState([]);
  const [loadingPost, setLoadingPost]: any = useState(false);

  const [filterProduct, setFilterProduct] : any = useState(posts)
  const [filter, setFilter] : any = useState('')

  const [tabActive, setTabActive] = useState<string>("Semua");


  const FilteredProduct = (data: any) => {
    if (data === tabActive) {
      return;
    }
    setTabActive(data);
    setFilter(data)
    const updateProduct = posts.filter((dataNow: any)=>{
      return dataNow.subcategory2_detail.subcategory1_detail.category_detail.name === data;
  })
    setFilterProduct(updateProduct)
  }
  useEffect(() => {

      axios
      .get(HOMECOURSE_URL)
      .then((res: any) => {
        setPosts(res.data);
        setLoading(true);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
  }, []);

  return (
    <>
    <div className={`nc-PageSearchV2 ${className}`} data-nc-id="PageSearchV2">
      {/* <HeadBackgroundCommon className="h-24 2xl:h-28" /> */}
      <Helmet>
        <title>Kelas Bahasa Untuk Ibu</title>
      </Helmet>
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <main>
          {/* TABS FILTER */}
          {/* <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row ">
            <Nav
              containerClassName="w-full overflow-x-auto hiddenScrollbar"
              className=" sm:space-x-2"
            >
              <NavItem
                isActive={tabActive === "Semua"}
                onClick={() => FilteredProduct("Semua")}
              >
                Semua
              </NavItem>
              {loading && category.map((item : any) => (
                <NavItem
                  key={item.id}
                  isActive={tabActive === item.name}
                  onClick={() => FilteredProduct(item.name)}
                >
                  {item.name}
                </NavItem>
              ))}
            </Nav>
          </div> */}

          {/* LOOP ITEMS */}
          {loading && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:gap-8 mt-8 lg:mt-10">
              {posts.map((post : any) => (
                <Card11 key={post.id} post={post} />
              ))}
            </div>
          )}
        </main>
      </div>
    </div>
    </>
  );
};

export default PagePostArticle;
