import { FC } from "react";
import NcImage from "components/NcImage/IPINcImage";
import PostCardMeta from "components/PostCardMeta/IPIPostCardMeta";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";

export interface Card12Props {
  className?: string;
  post: any;
}

const DetailHandler = (history: any, info: any) => {
  history.push({
    pathname: `/article/${info.id}`,
    state: { data: info },
  });
};

const Card12: FC<Card12Props> = ({ className = "h-full", post }) => {
  const { title, image, content } = post;
  const history = useHistory();

  return (
    <div
      className={`nc-Card12 group relative flex flex-col ${className}`}
      data-nc-id="Card12"
    >
      <div
        onClick={() => DetailHandler(history, post)}
        className="block flex-shrink-0 flex-grow relative w-full h-0 aspect-w-4 aspect-h-3 rounded-3xl overflow-hidden"
      >
        <NcImage
          containerClassName="absolute inset-0"
          src={image}
          alt={title}
        />
      </div>

  

      <div className=" mt-8 pr-10 flex flex-col">
        <h2
          className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 transition-colors text-lg sm:text-2xl`}
        >
          <div
            onClick={() => DetailHandler(history, post)}
            className="line-clamp-2"
            title={title}
          >
            {title}
          </div>
        </h2>
        <span className="hidden sm:block mt-4 text-neutral-500 dark:text-neutral-400">
          <span className="line-clamp-2"> {ReactHtmlParser(content)}</span>
        </span>
        <PostCardMeta className="mt-5" meta={post} />
      </div>
    </div>
  );
};

export default Card12;
