import React, {useState, useEffect} from "react";
import NcLink from "components/NcLink/IPINcLink";
import { LOCALSTORAGE_USERDETAIL } from '../../utils/Types';

const DashboardRoot = () => {
  const [myUser, setMyUser] : any = useState(null);
  const [loading, setLoading] : any = useState(false);
  useEffect(() => {
    const localdata : any = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL) || '{}');
    setMyUser(localdata);
    setLoading(true)
  }, []);
  return (
    <div className="rounded-xl min-h-full text-sm border border-neutral-100 dark:border-neutral-800 p-6 md:text-base">
      <span className="block text-lg mb-3">
        👋 Hello <strong>{loading && myUser.fullname}</strong>
        {/*, (not <strong>admin</strong>?{" "} */}
        {/* <NcLink to="#">Sign out</NcLink>) */}
      </span>
      Dari dasbor akun Anda, Anda dapat
      {` `}
      <NcLink to="#"> mengelola Artikel</NcLink>,
      <NcLink to="#"> melihat aktivitas kegiatan & webinar IPI</NcLink>,
      <NcLink to="#"> mengelola kompetisi IPI</NcLink>,
      <NcLink to="#"> mengubah profil Anda</NcLink>.
    </div>
  );
};

export default DashboardRoot;
