/* eslint-disable jsx-a11y/alt-text */
import ButtonPrimary from "components/Button/IPIButtonPrimary";
import SectionAds from "containers/PageHome/IPISectionAds";
import SectionPsycology from "containers/PageHome/IPISectionPsycology";
import React, { FC, ReactNode } from "react";
import rightImg1 from "images/hero-right.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { LOCALSTORAGE_TOKEN } from "utils/Types";

export interface SectionHeroProps {
  className?: string;
  rightImg: any;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            {heading}
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading}
          </span>
          {token == null && !!btnText && (
            <ButtonPrimary href="/signup">{btnText}</ButtonPrimary>
          )}
        </div>
        <div className="flex-grow">
          {rightImg.length !== 0 ? (
            <Carousel>
              {rightImg.length > 0 ? (
                rightImg.map((item: any, index: any) => (
                  <img key={index} className="w-full" src={item.image} alt="" />
                ))
              ) : (
                <>
                  <img className="w-full" src={rightImg} alt="" />
                  <img className="w-full" src={rightImg} alt="" />
                </>
              )}
            </Carousel>
          ) : (
            <img className="w-full" src={rightImg1} alt="" />
          )}
        </div>
      </div>
      <br></br>
      <SectionAds className=" sm: pt-8" />
      <SectionPsycology className="sm: pt-8" />
    </div>
  );
};

export default SectionHero;
