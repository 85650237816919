import React, { FC, useState, useEffect, Fragment } from "react";
import Logo from "components/Logo/Logo";
import Navigation from "components/Navigation/Navigation";
import ButtonPrimary from "components/Button/IPIButtonPrimary";
import MenuBar from "components/MenuBar/MenuBar";
import DarkModeContainer from "containers/DarkModeContainer/DarkModeContainer";
import { LOCALSTORAGE_USERDETAIL } from "../../utils/Types";
import { useHistory } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

export interface MainNav1Props {
  isTop: boolean;
}
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const [myUser, setMyUser]: any = useState(null);
  const [loading, setLoading]: any = useState(false);
  const history: any = useHistory();

  useEffect(() => {
    const localdata: any = JSON.parse(
      localStorage.getItem(LOCALSTORAGE_USERDETAIL) || "{}"
    );
    setMyUser(localdata);
    setLoading(true);
  }, []);
  return (
    <div
      className={`nc-MainNav1 relative z-10 ${
        isTop ? "onTop " : "notOnTop backdrop-filter"
      }`}
    >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo />
          <Navigation />
        </div>
        {loading && myUser.email != null ? (
          <>
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                  {myUser.email}
                  <ChevronDownIcon
                    className="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/dashboard"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Dashboard
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            localStorage.clear();
                            history.push({
                              pathname: "/",
                            });
                            window.location.reload();
                          }}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block w-full text-left px-4 py-2 text-sm"
                          )}
                        >
                          Sign out
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </>
        ) : (
          <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
            <div className="hidden items-center xl:flex space-x-1">
              <DarkModeContainer />
              {/* <SearchDropdown /> */}
              <div className="px-1" />
              <ButtonPrimary href="/login">Masuk</ButtonPrimary>
            </div>
            <div className="flex items-center xl:hidden">
              <ButtonPrimary href="/login">Masuk</ButtonPrimary>
              <div className="px-1" />
              <MenuBar />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainNav1;
