import React, { useState, useEffect, Fragment } from "react";
import SingleContent from "./SingleContent";
import moment from "moment";
import SingleHeader from "./SingleHeader";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import Certificate from "./Certificate";
import ButtonPrimary from "components/Button/IPIButtonPrimary";
import Select from "components/Select/Select";
import axios from "axios";
import Input from "components/Input/IPIInput";
import Label from "components/Label/Label";
import { LOCALSTORAGE_USERDETAIL, LOCALSTORAGE_TOKEN } from "utils/Types";
import {
  SURVEYQUESTION_EVENT_URL,
  SURVEYANSWER_EVENT_URL,
} from "utils/endpoint";

const TABS = ["Deskripsi", "Materi", "Absensi & Evaluasi", "Sertifikat"];

const DetailEventUser = (stateOri: any) => {
  const { location } = stateOri;
  const { state } = location;
  const { data } = state;
  const [tabActive, setTabActive] = useState<string>(TABS[0]);

  const handleClickTab = (item: string) => {
    if (item === tabActive) {
      return;
    }
    setTabActive(item);
  };
  const [myUser, setMyUser]: any = useState(null);
  const [question, setQuestion]: any = useState([]);
  const [multipleChoiceAnswer, setMultipleChoiceAnswer]: any = useState([]);
  useEffect(() => {
    const localdata: any = JSON.parse(
      localStorage.getItem(LOCALSTORAGE_USERDETAIL) || "{}"
    );
    setMyUser(localdata);
    axios
      .get(SURVEYQUESTION_EVENT_URL)
      .then((res: any) => {
        setQuestion(res.data);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    for (let i = 0; i < question.length; i++) {
      const pushdata = new FormData();
      pushdata.append("user_id", myUser.id);
      pushdata.append("event_id", data.event_ipi);
      pushdata.append("survey_id", question[i].id);
      if (question[i].type === "multi choices") {
        var filterAnsByID = multipleChoiceAnswer.filter(function (e: any) {
          return e.question_id === question[i].id;
        });
        if (filterAnsByID.length !== 0) {
          var selectedAns = filterAnsByID[0].ans;
          //count jumlah checked bo
          var numCheckedBox = selectedAns.length;
          for (let j = 0; j < numCheckedBox; j++) {
            //TODO dapet nilai check box
            pushdata.append("answer_text", selectedAns[j]);
            const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
              },
            };
            await axios
              .post(SURVEYANSWER_EVENT_URL, pushdata, options)
              .then((error) => {
                // const userdetail = userData.data;
                // localStorage.setItem(LOCALSTORAGE_USERDETAIL, JSON.stringify(userData.data));
                // window.location.reload();
                // history.push({
                //   pathname: "/dashboard/posts",
                //   state: { data: listingAttendance },
                // });
              })
              .catch((error) => {
                console.log(error.response.data);
              })
              .catch((error) => {
                console.log(error.response.data);
              });
          }
        }
      } else {
        if (e.target.survey[i]?.value !== undefined) {
          pushdata.append("answer_text", e.target.survey[i].value);
          const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
          const options = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          };
          await axios
            .post(SURVEYANSWER_EVENT_URL, pushdata, options)
            .then((error) => {
              // const userdetail = userData.data;
              // localStorage.setItem(LOCALSTORAGE_USERDETAIL, JSON.stringify(userData.data));
              // window.location.reload();
              // history.push({
              //   pathname: "/dashboard/posts",
              //   state: { data: listingAttendance },
              // });
            })
            .catch((error) => {
              console.log(error.response.data);
            })
            .catch((error) => {
              console.log(error.response.data);
            });
        }
      }

      if (i === question.length - 1) {
        alert("Survey berhasil dikirimkan");
        window.location.reload();
      }
    }
  };
  return (
    <>
      <div
        className={`nc-PageSingleTemp3Sidebar`}
        data-nc-id="PageSingleTemp3Sidebar"
      >
        <header className="relative pt-16 z-10 md:py-20 lg:py-28 bg-neutral-900 dark:bg-black">
          {/* SINGLE HEADER */}
          <div className="dark container relative z-10">
            <div className="max-w-screen-md">
              <SingleHeader
                hiddenDesc
                metaActionStyle="style2"
                pageData={data}
              />
            </div>
          </div>

          {/* FEATURED IMAGE */}
          <div className="mt-8 md:mt-0 md:absolute md:top-0 md:right-0 md:bottom-0 md:w-1/2 lg:w-2/5 2xl:w-1/3">
            <div className="hidden md:block absolute top-0 left-0 bottom-0 w-1/5 from-neutral-900 dark:from-black bg-gradient-to-r"></div>
            <img
              className="block w-full h-full object-cover"
              src={data.event_detail.image}
              alt=""
            />
          </div>
        </header>

        {/* SINGLE MAIN CONTENT */}
        <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row ">
            <Nav
              containerClassName="w-full overflow-x-auto hiddenScrollbar"
              className=" sm:space-x-2"
            >
              {TABS.map((item, index) => (
                <NavItem
                  key={index}
                  isActive={tabActive === item}
                  onClick={() => handleClickTab(item)}
                >
                  {item}
                </NavItem>
              ))}
              {data.event_detail.type === "kulwap" ||
              data.event_detail.type === "webinar" ||
              data.event_detail.type === "kursus/workshop" ? (
                <NavItem
                  isActive={tabActive === "Link Whatsapps Group"}
                  onClick={() => handleClickTab("Link Whatsapps Group")}
                >
                  Link Whatsapps Group
                </NavItem>
              ) : (
                <NavItem
                  isActive={tabActive === "Link Zoom Meeting"}
                  onClick={() => handleClickTab("Link Zoom Meeting")}
                >
                  Link Zoom Meeting
                </NavItem>
              )}
            </Nav>
            <div className="block my-4 border-b w-full border-neutral-100 sm:hidden"></div>
          </div>
          {tabActive === "Deskripsi" && (
            <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-20">
              <SingleContent data={data} />
            </div>
          )}
          {tabActive === "Materi" && (
            <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-20">
              {/* <SingleContent data={data} /> */}
              {data.event_detail.file_material &&
              data.event_detail.file_material !== null ? (
                <a href={data.event_detail.file_material} target="blank">
                  Download Materi
                </a>
              ) : (
                "File materi belum Tersedia"
              )}
            </div>
          )}
          {tabActive === "Absensi & Evaluasi" && (
            <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-20">
              {moment(moment().format("YYYY-MM-DD")).isSame(
                data.event_detail.date
              ) && (moment(moment(), "hh:mm:ss").isBetween(moment(data.event_detail.time, "hh:mm:ss"), moment('23:59:00', "hh:mm:ss"))) ? (
                <form
                  className="grid md:grid-cols-2 gap-6"
                  onSubmit={handleSubmit}
                >
                  {question !== undefined &&
                    question.map((item: any) => (
                      <Fragment key={item.id}>
                        {item.type === "text" && (
                          <label className="block md:col-span-2">
                            {item.question_text === "Nama" && (
                              <>
                                <Label>{item.question_text}</Label>
                                <Input
                                  name="survey"
                                  type="text"
                                  value={myUser.fullname}
                                  placeholder={myUser.fullname}
                                  disabled
                                  className="mt-1"
                                />
                              </>
                            )}
                            {item.question_text === "Email" && (
                              <>
                                <Label>{item.question_text}</Label>
                                <Input
                                  name="survey"
                                  type="text"
                                  value={myUser.email}
                                  placeholder={myUser.email}
                                  disabled
                                  className="mt-1"
                                />
                              </>
                            )}
                            {item.question_text === "Nomor Handphone" && (
                              <>
                                <Label>{item.question_text}</Label>
                                <Input
                                  name="survey"
                                  type="text"
                                  value={myUser.phone}
                                  placeholder={myUser.phone}
                                  disabled
                                  className="mt-1"
                                />
                              </>
                            )}
                            {item.question_text === "Domisili" && (
                              <>
                                <Label>{item.question_text}</Label>
                                <Input
                                  name="survey"
                                  type="text"
                                  value={myUser.address ? myUser.address : "-"}
                                  placeholder={myUser.address}
                                  disabled
                                  className="mt-1"
                                />
                              </>
                            )}
                            {item.question_text !== "Domisili" &&
                              item.question_text !== "Nomor Handphone" &&
                              item.question_text !== "Email" &&
                              item.question_text !== "Nama" && (
                                <>
                                  <Label>{item.question_text}</Label>
                                  <Input
                                    type="text"
                                    name="survey"
                                    placeholder="Silahkan isi disini"
                                    className="mt-1"
                                  />
                                </>
                              )}
                          </label>
                        )}
                        {item.type === "single choices" && (
                          <label className="block  md:col-span-2">
                            <Label>{item.question_text}</Label>
                            <Select className="mt-1" name="survey">
                              <option defaultValue="" disabled>
                                Pilih Salah Satu
                              </option>
                              {item.choices_1 && (
                                <option>{item.choices_1}</option>
                              )}
                              {item.choices_2 && (
                                <option>{item.choices_2}</option>
                              )}
                              {item.choices_3 && (
                                <option>{item.choices_3}</option>
                              )}
                              {item.choices_4 && (
                                <option>{item.choices_4}</option>
                              )}
                              {item.choices_5 && (
                                <option>{item.choices_5}</option>
                              )}
                              {item.choices_6 && (
                                <option>{item.choices_6}</option>
                              )}
                            </Select>
                          </label>
                        )}
                        {item.type === "multi choices" && (
                          <label className="block  md:col-span-2">
                            <Label>{item.question_text}</Label>
                            {item.choices_1 && (
                              <>
                                <div className="form-check">
                                  <input
                                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="checkbox"
                                    value=""
                                    id={item.choices_1}
                                    name="choices_1"
                                    onChange={(e: any) => {
                                      var checkBoxValue = e.target.checked;
                                      var tempVal = multipleChoiceAnswer; // [{question_id:1, ans:['choice_1', 'choices',...]},{}]
                                      //filter semua jawaban multiple choices yg ada berdasarkan question ID
                                      var filterObj = [];
                                      if (tempVal.length !== 0) {
                                        filterObj = tempVal.filter(function (
                                          e: any
                                        ) {
                                          return e.question_id === item.id;
                                        });
                                      }
                                      if (filterObj.length !== 0) {
                                        // apabila sudah ada jawaban sebelumnya
                                        var currentAns = filterObj[0];
                                        var answers = currentAns.ans;
                                        var isAvailable =
                                          answers.indexOf(item.choices_1) === -1
                                            ? false
                                            : true; //cek apakah  sudah pernah pilih
                                        var currentChoice;
                                        if (isAvailable) {
                                          if (!checkBoxValue) {
                                            var ansIndex = answers.indexOf(
                                              item.choices_1
                                            );
                                            answers.splice(ansIndex, 1);
                                            if (answers.length === 0) {
                                              currentChoice = null;
                                            } else {
                                              currentChoice = {
                                                question_id: item.id,
                                                ans: answers,
                                              };
                                            }
                                          }
                                        } else {
                                          if (checkBoxValue) {
                                            answers.push(item.choices_1);
                                            currentChoice = {
                                              question_id: item.id,
                                              ans: answers,
                                            };
                                          }
                                        }
                                        //find index old answer
                                        var index =
                                          multipleChoiceAnswer.findIndex(
                                            (x: any) =>
                                              x.question_id === item.id
                                          );
                                        var tempResult = multipleChoiceAnswer;
                                        tempResult.splice(index, 1);
                                        if (currentChoice) {
                                          tempResult.push(currentChoice);
                                        }
                                        //buang choice lama, tambahkan yg baru
                                        setMultipleChoiceAnswer(tempResult);
                                      } else {
                                        // tambahkan jawaban baru apabila tidak ada di sebelumnya
                                        if (checkBoxValue) {
                                          currentChoice = {
                                            question_id: item.id,
                                            ans: [item.choices_1],
                                          };
                                          var temp = multipleChoiceAnswer;
                                          temp.push(currentChoice);
                                          setMultipleChoiceAnswer(temp);
                                        }
                                      }
                                    }}
                                  />
                                  <label
                                    className="form-check-label inline-block text-gray-800"
                                    htmlFor={item.choices_1}
                                  >
                                    {item.choices_1}
                                  </label>
                                </div>
                              </>
                            )}
                            {item.choices_2 && (
                              <>
                                <label className="block">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                      type="checkbox"
                                      value=""
                                      id={item.choices_2}
                                      name="choices_2"
                                      onChange={(e: any) => {
                                        var checkBoxValue = e.target.checked;
                                        var tempVal = multipleChoiceAnswer; // [{question_id:1, ans:['choice_1', 'choices',...]},{}]
                                        //filter semua jawaban multiple choices yg ada berdasarkan question ID
                                        var filterObj = [];
                                        if (tempVal.length !== 0) {
                                          filterObj = tempVal.filter(function (
                                            e: any
                                          ) {
                                            return e.question_id === item.id;
                                          });
                                        }
                                        if (filterObj.length !== 0) {
                                          // apabila sudah ada jawaban sebelumnya
                                          var currentAns = filterObj[0];
                                          var answers = currentAns.ans;
                                          var isAvailable =
                                            answers.indexOf(item.choices_2) ===
                                            -1
                                              ? false
                                              : true; //cek apakah  sudah pernah pilih
                                          var currentChoice;
                                          if (isAvailable) {
                                            if (!checkBoxValue) {
                                              var ansIndex = answers.indexOf(
                                                item.choices_2
                                              );
                                              answers.splice(ansIndex, 1);
                                              if (answers.length === 0) {
                                                currentChoice = null;
                                              } else {
                                                currentChoice = {
                                                  question_id: item.id,
                                                  ans: answers,
                                                };
                                              }
                                            }
                                          } else {
                                            if (checkBoxValue) {
                                              answers.push(item.choices_2);
                                              currentChoice = {
                                                question_id: item.id,
                                                ans: answers,
                                              };
                                            }
                                          }
                                          //find index old answer
                                          var index =
                                            multipleChoiceAnswer.findIndex(
                                              (x: any) =>
                                                x.question_id === item.id
                                            );
                                          var tempResult = multipleChoiceAnswer;
                                          tempResult.splice(index, 1);
                                          if (currentChoice) {
                                            tempResult.push(currentChoice);
                                          }
                                          //buang choice lama, tambahkan yg baru
                                          setMultipleChoiceAnswer(tempResult);
                                        } else {
                                          // tambahkan jawaban baru apabila tidak ada di sebelumnya
                                          if (checkBoxValue) {
                                            currentChoice = {
                                              question_id: item.id,
                                              ans: [item.choices_2],
                                            };
                                            var temp = multipleChoiceAnswer;
                                            temp.push(currentChoice);
                                            setMultipleChoiceAnswer(temp);
                                          }
                                        }
                                      }}
                                    />
                                    <label
                                      className="form-check-label inline-block text-gray-800"
                                      htmlFor={item.choices_2}
                                    >
                                      {item.choices_2}
                                    </label>
                                  </div>
                                </label>
                              </>
                            )}
                            {item.choices_3 && (
                              <>
                                <label className="block">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                      type="checkbox"
                                      value=""
                                      id={item.choices_3}
                                      name="choices_3"
                                      onChange={(e: any) => {
                                        var checkBoxValue = e.target.checked;
                                        var tempVal = multipleChoiceAnswer; // [{question_id:1, ans:['choice_1', 'choices',...]},{}]
                                        //filter semua jawaban multiple choices yg ada berdasarkan question ID
                                        var filterObj = [];
                                        if (tempVal.length !== 0) {
                                          filterObj = tempVal.filter(function (
                                            e: any
                                          ) {
                                            return e.question_id === item.id;
                                          });
                                        }
                                        if (filterObj.length !== 0) {
                                          // apabila sudah ada jawaban sebelumnya
                                          var currentAns = filterObj[0];
                                          var answers = currentAns.ans;
                                          var isAvailable =
                                            answers.indexOf(item.choices_3) ===
                                            -1
                                              ? false
                                              : true; //cek apakah  sudah pernah pilih
                                          var currentChoice;
                                          if (isAvailable) {
                                            if (!checkBoxValue) {
                                              var ansIndex = answers.indexOf(
                                                item.choices_3
                                              );
                                              answers.splice(ansIndex, 1);
                                              if (answers.length === 0) {
                                                currentChoice = null;
                                              } else {
                                                currentChoice = {
                                                  question_id: item.id,
                                                  ans: answers,
                                                };
                                              }
                                            }
                                          } else {
                                            if (checkBoxValue) {
                                              answers.push(item.choices_3);
                                              currentChoice = {
                                                question_id: item.id,
                                                ans: answers,
                                              };
                                            }
                                          }
                                          //find index old answer
                                          var index =
                                            multipleChoiceAnswer.findIndex(
                                              (x: any) =>
                                                x.question_id === item.id
                                            );
                                          var tempResult = multipleChoiceAnswer;
                                          tempResult.splice(index, 1);
                                          if (currentChoice) {
                                            tempResult.push(currentChoice);
                                          }
                                          //buang choice lama, tambahkan yg baru
                                          setMultipleChoiceAnswer(tempResult);
                                        } else {
                                          // tambahkan jawaban baru apabila tidak ada di sebelumnya
                                          if (checkBoxValue) {
                                            currentChoice = {
                                              question_id: item.id,
                                              ans: [item.choices_3],
                                            };
                                            var temp = multipleChoiceAnswer;
                                            temp.push(currentChoice);
                                            setMultipleChoiceAnswer(temp);
                                          }
                                        }
                                      }}
                                    />
                                    <label
                                      className="form-check-label inline-block text-gray-800"
                                      htmlFor={item.choices_3}
                                    >
                                      {item.choices_3}
                                    </label>
                                  </div>
                                </label>
                              </>
                            )}
                            {item.choices_4 && (
                              <>
                                <label className="block">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                      type="checkbox"
                                      value=""
                                      id={item.choices_4}
                                      name="choices_4"
                                      onChange={(e: any) => {
                                        var checkBoxValue = e.target.checked;
                                        var tempVal = multipleChoiceAnswer; // [{question_id:1, ans:['choice_1', 'choices',...]},{}]
                                        //filter semua jawaban multiple choices yg ada berdasarkan question ID
                                        var filterObj = [];
                                        if (tempVal.length !== 0) {
                                          filterObj = tempVal.filter(function (
                                            e: any
                                          ) {
                                            return e.question_id === item.id;
                                          });
                                        }
                                        if (filterObj.length !== 0) {
                                          // apabila sudah ada jawaban sebelumnya
                                          var currentAns = filterObj[0];
                                          var answers = currentAns.ans;
                                          var isAvailable =
                                            answers.indexOf(item.choices_4) ===
                                            -1
                                              ? false
                                              : true; //cek apakah  sudah pernah pilih
                                          var currentChoice;
                                          if (isAvailable) {
                                            if (!checkBoxValue) {
                                              var ansIndex = answers.indexOf(
                                                item.choices_4
                                              );
                                              answers.splice(ansIndex, 1);
                                              if (answers.length === 0) {
                                                currentChoice = null;
                                              } else {
                                                currentChoice = {
                                                  question_id: item.id,
                                                  ans: answers,
                                                };
                                              }
                                            }
                                          } else {
                                            if (checkBoxValue) {
                                              answers.push(item.choices_4);
                                              currentChoice = {
                                                question_id: item.id,
                                                ans: answers,
                                              };
                                            }
                                          }
                                          //find index old answer
                                          var index =
                                            multipleChoiceAnswer.findIndex(
                                              (x: any) =>
                                                x.question_id === item.id
                                            );
                                          var tempResult = multipleChoiceAnswer;
                                          tempResult.splice(index, 1);
                                          if (currentChoice) {
                                            tempResult.push(currentChoice);
                                          }
                                          //buang choice lama, tambahkan yg baru
                                          setMultipleChoiceAnswer(tempResult);
                                        } else {
                                          // tambahkan jawaban baru apabila tidak ada di sebelumnya
                                          if (checkBoxValue) {
                                            currentChoice = {
                                              question_id: item.id,
                                              ans: [item.choices_4],
                                            };
                                            var temp = multipleChoiceAnswer;
                                            temp.push(currentChoice);
                                            setMultipleChoiceAnswer(temp);
                                          }
                                        }
                                      }}
                                    />
                                    <label
                                      className="form-check-label inline-block text-gray-800"
                                      htmlFor={item.choices_4}
                                    >
                                      {item.choices_4}
                                    </label>
                                  </div>
                                </label>
                              </>
                            )}
                            {item.choices_5 && (
                              <>
                                <label className="block">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                      type="checkbox"
                                      value=""
                                      id={item.choices_5}
                                      name="choices_5"
                                      onChange={(e: any) => {
                                        var checkBoxValue = e.target.checked;
                                        var tempVal = multipleChoiceAnswer; // [{question_id:1, ans:['choice_1', 'choices',...]},{}]
                                        //filter semua jawaban multiple choices yg ada berdasarkan question ID
                                        var filterObj = [];
                                        if (tempVal.length !== 0) {
                                          filterObj = tempVal.filter(function (
                                            e: any
                                          ) {
                                            return e.question_id === item.id;
                                          });
                                        }
                                        if (filterObj.length !== 0) {
                                          // apabila sudah ada jawaban sebelumnya
                                          var currentAns = filterObj[0];
                                          var answers = currentAns.ans;
                                          var isAvailable =
                                            answers.indexOf(item.choices_5) ===
                                            -1
                                              ? false
                                              : true; //cek apakah  sudah pernah pilih
                                          var currentChoice;
                                          if (isAvailable) {
                                            if (!checkBoxValue) {
                                              var ansIndex = answers.indexOf(
                                                item.choices_5
                                              );
                                              answers.splice(ansIndex, 1);
                                              if (answers.length === 0) {
                                                currentChoice = null;
                                              } else {
                                                currentChoice = {
                                                  question_id: item.id,
                                                  ans: answers,
                                                };
                                              }
                                            }
                                          } else {
                                            if (checkBoxValue) {
                                              answers.push(item.choices_5);
                                              currentChoice = {
                                                question_id: item.id,
                                                ans: answers,
                                              };
                                            }
                                          }
                                          //find index old answer
                                          var index =
                                            multipleChoiceAnswer.findIndex(
                                              (x: any) =>
                                                x.question_id === item.id
                                            );
                                          var tempResult = multipleChoiceAnswer;
                                          tempResult.splice(index, 1);
                                          if (currentChoice) {
                                            tempResult.push(currentChoice);
                                          }
                                          //buang choice lama, tambahkan yg baru
                                          setMultipleChoiceAnswer(tempResult);
                                        } else {
                                          // tambahkan jawaban baru apabila tidak ada di sebelumnya
                                          if (checkBoxValue) {
                                            currentChoice = {
                                              question_id: item.id,
                                              ans: [item.choices_5],
                                            };
                                            var temp = multipleChoiceAnswer;
                                            temp.push(currentChoice);
                                            setMultipleChoiceAnswer(temp);
                                          }
                                        }
                                      }}
                                    />
                                    <label
                                      className="form-check-label inline-block text-gray-800"
                                      htmlFor={item.choices_5}
                                    >
                                      {item.choices_5}
                                    </label>
                                  </div>
                                </label>
                              </>
                            )}
                            {item.choices_6 && (
                              <>
                                <label className="block">
                                  <Label>{item.choices_6}</Label>
                                  <Input
                                    name="choices_6"
                                    type="checkbox"
                                    className="mt-1"
                                    onChange={(e: any) => {
        
                                      var checkBoxValue = e.target.checked;
                                      var tempVal = multipleChoiceAnswer; // [{question_id:1, ans:['choice_1', 'choices',...]},{}]
                                      //filter semua jawaban multiple choices yg ada berdasarkan question ID
                                      var filterObj = [];
                                      if (tempVal.length !== 0) {
                                        filterObj = tempVal.filter(function (
                                          e: any
                                        ) {
                                          return e.question_id === item.id;
                                        });
                                      }
                                      if (filterObj.length !== 0) {
                                        // apabila sudah ada jawaban sebelumnya
                                        var currentAns = filterObj[0];
                                        var answers = currentAns.ans;
                                        var isAvailable =
                                          answers.indexOf(item.choices_6) === -1
                                            ? false
                                            : true; //cek apakah  sudah pernah pilih
                                        var currentChoice;
                                        if (isAvailable) {
                                          if (!checkBoxValue) {
                                            var ansIndex = answers.indexOf(
                                              item.choices_6
                                            );
                                            answers.splice(ansIndex, 1);
                                            if (answers.length === 0) {
                                              currentChoice = null;
                                            } else {
                                              currentChoice = {
                                                question_id: item.id,
                                                ans: answers,
                                              };
                                            }
                                          }
                                        } else {
                                          if (checkBoxValue) {
                                            answers.push(item.choices_6);
                                            currentChoice = {
                                              question_id: item.id,
                                              ans: answers,
                                            };
                                          }
                                        }
                                        //find index old answer
                                        var index =
                                          multipleChoiceAnswer.findIndex(
                                            (x: any) =>
                                              x.question_id === item.id
                                          );
                                        var tempResult = multipleChoiceAnswer;
                                        tempResult.splice(index, 1);
                                        if (currentChoice) {
                                          tempResult.push(currentChoice);
                                        }
                                        //buang choice lama, tambahkan yg baru
                                        setMultipleChoiceAnswer(tempResult);
                                      } else {
                                        // tambahkan jawaban baru apabila tidak ada di sebelumnya
                                        if (checkBoxValue) {
                                          currentChoice = {
                                            question_id: item.id,
                                            ans: [item.choices_6],
                                          };
                                          var temp = multipleChoiceAnswer;
                                          temp.push(currentChoice);
                                          setMultipleChoiceAnswer(temp);
                                        }
                                      }
                                    }}
                                  />
                                </label>
                              </>
                            )}
                          </label>
                        )}
                      </Fragment>
                    ))}
                  <ButtonPrimary className="md:col-span-2" type="submit">
                    Kirim
                  </ButtonPrimary>
                </form>
              ) : (
                <div>
                  Absensi Hanya dapat dilakukan ketika acara dimulai hingga
                  23:59:00 di hari yang sama dengen kegiatan
                </div>
              )}
            </div>
          )}
          {tabActive === "Sertifikat" && (
            <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-20">
              <Certificate person={myUser} course={data} />
            </div>
          )}
          {tabActive === "Link Whatsapps Group" && (
            <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-20">
              <a href={data.event_detail.url_subscribed}>
                Link Whatsapps Group
              </a>
            </div>
          )}
        </div>
        {/* <div className="container flex flex-col my-10 lg:flex-row ">
          <div className="w-full mt-12 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3">
            <Sidebar />
          </div>
        </div> */}

        {/* RELATED POSTS */}
        {/* <SingleRelatedPosts /> */}
      </div>
    </>
  );
};

export default DetailEventUser;
