import { FC, useEffect, useState } from "react";
import Heading from "components/Heading/IPIHeading";
import Glide from "@glidejs/glide";
import ncNanoId from "utils/ncNanoId";
import axios from "axios";
import { COMPETITIONS_URL, HOMEPAGECOMPETITIONS_URL } from "../../../utils/endpoint";
import { useHistory } from "react-router-dom";
import PostFeaturedMedia from "components/PostFeaturedMedia/IPIPostFeaturedMedia";
import moment from "moment";
export interface SectionSliderPostsProps {
  className?: string;
  heading: string;
  subHeading?: string;
  perView?: 2 | 3 | 4;
}
const DetailHandler = (history: any, info: any) => {
  history.push({
    pathname: `/competition/${info.id}`,
    state: { data: info },
  });
};

const IPISectionSliderCompetitions: FC<SectionSliderPostsProps> = ({
  heading,
  subHeading,
  className = "",
  perView = 4,
}) => {
  const UNIQUE_CLASS = ncNanoId("sliderPosts_");

  const MY_GLIDE = new Glide(`.${UNIQUE_CLASS}`, {
    // @ts-ignore
    direction:
      document.querySelector("html")?.getAttribute("dir") === "rtl"
        ? "rtl"
        : "ltr",
    perView: perView,
    gap: 32,
    bound: true,
    breakpoints: {
      1280: {
        perView: perView - 1,
      },
      1023: {
        perView: perView - 2 || 1.2,
        gap: 20,
      },
      767: {
        perView: perView - 2 || 1.2,
        gap: 20,
      },
      639: {
        perView: 1.2,
        gap: 20,
      },
    },
  });

  useEffect(() => {
    if (!MY_GLIDE) return;
    MY_GLIDE.mount();
  }, [MY_GLIDE]);
  const [competition, setCompetitions]: any = useState([]);
  const [loadingCompetitions, setLoadingCompetitions]: any = useState(false);
  const [isHover, setIsHover] = useState(false);
  const history: any = useHistory(); 

  useEffect(() => {
    axios
      .get(HOMEPAGECOMPETITIONS_URL)
      .then((res: any) => {
        setCompetitions(res.data);
        setLoadingCompetitions(true);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
  }, []);
  competition.sort(
    (firstItem: any, secondItem: any) => secondItem.id - firstItem.id
  );
  return (
    <div className={`nc-SectionSliderPosts ${className}`}>
      <div className={`${UNIQUE_CLASS}`}>
        <Heading desc={subHeading} hasNextPrev>
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {loadingCompetitions ? (
              competition.map((item: any) => (
                <li key={item.id} className={`glide__slide h-auto `}>
                  <div
                    className={`nc-Card10 relative flex flex-col h-full`}
                    data-nc-id="Card10"
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                  >
                    <div
                      onClick={() => DetailHandler(history, item)}
                      className="block  group rounded-3xl flex-shrink-0 relative w-full aspect-w-9 aspect-h-7 sm:aspect-h-10 overflow-hidden"
                    >
                      <div>
                        <PostFeaturedMedia post={item} isHover={isHover} />
                      </div>

                      <span className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 group-hover:opacity-100 transition-opacity"></span>
                    </div>
                    <div className="space-y-2.5 mt-4">
                      <div
                        className={`nc-PostCardMetaV2 inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 ${"text-xs"} leading-none`}
                        data-nc-id="PostCardMetaV2"
                      >
                        <div onClick={() => DetailHandler(history, item)}>
                          <h2 className={`block font-semibold ${"text-base"}`}>
                            <span className="line-clamp-1">{item.name}</span>
                          </h2>

                          <div className="flex mt-1.5">
                            <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
                              Dibuka
                            </span>
                            <span className="text-neutral-500 dark:text-neutral-400 font-normal">
                              {moment(item.start_date).format("DD-MM-YYYY")}{" "}
                              <strong>s.d.</strong>{" "}
                              {moment(item.end_date).format("DD-MM-YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <div>loading...</div>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default IPISectionSliderCompetitions;
