import React, { FC, useState, useEffect } from "react";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import { Helmet } from "react-helmet";
import Card10 from "./Card10";
import axios from "axios";
import { COMPETITIONS_URL } from "../../../utils/endpoint";

export interface PageSearchV2Props {
  className?: string;
}

const CompetitionPage: FC<PageSearchV2Props> = ({ className = "" }) => {
  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  const [posts, setPosts]: any = useState([]);
  const [loadingPost, setLoadingPost]: any = useState(false);

  const [tabActive, setTabActive] = useState<string>("Semua");

  const FilteredProduct = (data: any) => {
    if (data === tabActive) {
      return;
    }
    setTabActive(data);
  };
  useEffect(() => {
    axios
      .get(COMPETITIONS_URL)
      .then((res: any) => {
        setPosts(res.data);
        setLoadingPost(true);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
  }, []);

  posts.sort((firstItem: any, secondItem: any) => secondItem.id - firstItem.id);

  return (
    <div className={`nc-PageSearchV2 ${className}`} data-nc-id="PageSearchV2">
      {/* <HeadBackgroundCommon className="h-24 2xl:h-28" /> */}
      <Helmet>
        <title>Kompetisi</title>
      </Helmet>
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <main>
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row ">
            <Nav
              containerClassName="w-full overflow-x-auto hiddenScrollbar"
              className=" sm:space-x-2"
            >
              <NavItem
                isActive={tabActive === "Semua"}
                onClick={() => FilteredProduct("Semua")}
              >
                Semua
              </NavItem>
            </Nav>
            <div className="block my-4 border-b w-full border-neutral-100 sm:hidden"></div>
          </div>

          {/* LOOP ITEMS */}
          {loadingPost && tabActive === "Semua" && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:gap-8 mt-8 lg:mt-10">
              {posts.map((post: any) => (
                <Card10 key={post.id} post={post} />
              ))}
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default CompetitionPage;
