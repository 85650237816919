import React, { FC, useState, useEffect } from "react";
import PageEBook from "./PageEBook";
import Heading from "components/Heading/IPIHeading";
import Helmet from 'react-helmet';
import axios from "axios";
import { LIBRARY_URL } from "utils/endpoint";


export interface PageLibraryProps {
  posts?: any;
  className?: string;
  gapClassName?: string;
  heading?: string;
}

const PageLibrary: FC<PageLibraryProps> = ({
  posts,
  className = "",
  gapClassName = "gap-6 md:gap-8",
  heading = "Perpustakaan",
}) => {
  const [book, setBooks] : any = useState();
  useEffect(() => {
    axios
    .get(LIBRARY_URL)
    .then((res : any) => {
      setBooks(res.data);
    })
    .catch((error : any) => {
      console.log(error.response.data);
    });
  }, []);
  return (
    <div className="nc-PageHome relative">
    <Helmet>
      <title>Perpustakaan</title>
    </Helmet>

    {/* ======== ALL SECTIONS ======== */}
    <div className="relative overflow-hidden">
    <div className="relative py-16">
    <div className="container ">
      <div className={`nc-SectionMagazine9 relative ${className}`}>
        {heading && (
          <Heading desc={"Anda bisa mengunduh e-book kami gratis"}>
            {heading}
          </Heading>
        )}
        <div
          className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 ${gapClassName}`}
        >
        </div>
        <div
          className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 ${gapClassName} mt-8`}
        >
          {book !== undefined && book.map((p:any) => (
              <PageEBook key={p.id} post={p} />
            ))}
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
  
  );
};

export default PageLibrary;
