import ButtonPrimary from "components/Button/IPIButtonPrimary";
import React, { FC, ReactNode } from "react";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const SectionHeroSejarah: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            {heading}
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading}
          </span>
         <p>Kemajuan suatu negara atau suatu keluarga ditentukan oleh kualitas wanita/Ibu yang ada didalamnya. Seorang istri dan ibu harus menjadi individu yang berkualitas dalam segala hal didalam keluarga khususnya dan di masyarakat pada umumnya.</p>
          <p>Maka dari itu para founder membentuk Ibu Pembelajar Indonesia, suatu komunitas yang berbasis pendidikan agar bisa menjadi suatu wadah untuk para Ibu bisa terus berkarya dan berkembang.</p>
          {!!btnText && <ButtonPrimary href="/login">{btnText}</ButtonPrimary>}
        </div>
      </div>
    </div>
  );
};

export default SectionHeroSejarah;
