import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import NcImage from "components/NcImage/IPINcImage";
import { Link } from "react-router-dom";
import moment from "moment";
import Avatar from "components/Avatar/Avatar";

export interface Card10Props {
  className?: string;
  post: any;
  ratio?: string;
}
const DetailHandler = (history: any, info: any) => {
  history.push({
    pathname: `/competition/${info.id}`,
    state: { data: info },
  });
};

const Card10: FC<Card10Props> = ({
  className = "h-full",
  ratio = "aspect-w-4 aspect-h-4",
  post,
}) => {
  const history = useHistory();

  return (
    <div
      className={`nc-Card10 relative flex flex-col ${className}`}
      data-nc-id="Card10"
    >
      <div
        onClick={() => DetailHandler(history, post)}
        className="block  group rounded-3xl flex-shrink-0 relative w-full aspect-w-9 aspect-h-7 sm:aspect-h-10 overflow-hidden"
      >
        <div>
          {/* <PostFeaturedMedia post={post} isHover={isHover} /> */}
          <div
            className={`nc-PostFeaturedMedia relative w-full h-full `}
            data-nc-id="PostFeaturedMedia"
          >
            <NcImage containerClassName="absolute inset-0" src={post.image} />
          </div>
        </div>

        <span className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 group-hover:opacity-100 transition-opacity"></span>
      </div>

      <div className="space-y-2.5 mt-4">
        <div
          className={`nc-PostCardMetaV2 inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 text-xs leading-none`}
          data-nc-id="PostCardMetaV2"
        >
          <Link
            to="{author.href} "
            className="relative flex items-center space-x-2"
          >
            {!post && (
              <Avatar
                radius="rounded-full"
                sizeClass="h-9 w-9 text-base"
                imgUrl="{author.avatar}"
                userName="{author.displayName}"
              />
            )}
            <div>
              <h2 className={`block font-semibold text-base`}>
                <span className="line-clamp-1">{post.name}</span>
              </h2>

              <div className="flex mt-1.5">
                <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
                  {post.subtitle}
                </span>
                <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
                  Dibuka
                </span>
                <span className="text-neutral-500 dark:text-neutral-400 font-normal">
                  {moment(post.start_date).format("DD-MM-YYYY")}{" "}
                  <strong>s.d.</strong>{" "}
                  {moment(post.end_date).format("DD-MM-YYYY")}
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card10;
