import React, { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import { Sidebar } from "./Sidebar";
import { Helmet } from "react-helmet";
import axios from "axios";
import { POST_URL } from "../../../utils/endpoint";
import NcImage from "components/NcImage/IPINcImage";
import { Link } from "react-router-dom";
import Avatar from "components/Avatar/Avatar";
import moment from "moment";

const PageDetailPost = (stateOri: any) => {
  const { location } = stateOri;
  const { state } = location;
  const { data } = state;
  const [article, setArticle]: any = useState([]);
  const [loading, setLoading]: any = useState(false);

  useEffect(() => {
    axios
      .get(`${POST_URL}${data.id}`)
      .then((res: any) => {
        setArticle(res.data);
        setLoading(true);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
  }, []);
  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <>
      <div
        className={`nc-PageSingleTemp3Sidebar`}
        data-nc-id="PageSingleTemp3Sidebar"
      >
        <div className="container rounded-xl">
          {loading && loading && (
            <div className="max-w-screen-md mx-auto">
              <Helmet>
                <title>Artikel - {article.title}</title>
              </Helmet>
              <div className={`nc-SingleHeader`}>
                <div className="space-y-5 relative pt-16">
                  <h1
                    className={
                      "text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-5xl dark:text-neutral-100 max-w-4xl "
                    }
                    title={article.title}
                  >
                    {article.title}
                  </h1>
                  <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
                  <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
                    <div
                      className={`nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-xs leading-none`}
                      data-nc-id="PostMeta2"
                    >
                      <Link to="" className="flex items-center space-x-2">
                        <Avatar
                          radius="rounded-full shadow-inner"
                          sizeClass="h-6 w-6 text-sm"
                          imgUrl={article?.author_detail?.photo}
                          userName={article?.author_detail?.fullname}
                        />
                      </Link>
                      <div className="ml-3">
                        <div className="flex items-center">
                          <Link to="" className="block font-semibold">
                            {article?.author_detail?.fullname}
                          </Link>
                        </div>
                        <div className="text-xs mt-[6px]">
                          <span className="text-neutral-700 dark:text-neutral-300">
                            {moment(
                              article.created_at,
                              "YYYY-MM-DD | HH:mm:ss"
                            ).format("DD-MM-YYYY | HH:mm")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <NcImage
          containerClassName="container my-10 sm:my-12"
          className="object-cover w-full h-full rounded-xl"
          src={article.image}
        />

        <div className="container flex flex-col my-10 lg:flex-row ">
          {loading && loading && (
            <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-20">
              <div className="nc-SingleContent space-y-10">
                <div
                  id="single-entry-content"
                  className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
                >
                  <div>{HtmlParser(data.content)}</div>
                </div>

                {/* AUTHOR */}
                <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
                <div className="max-w-screen-md mx-auto ">
                  <div className="nc-SingleAuthor flex">
                    <Link to="www.google.com">
                      <Avatar
                        imgUrl={article?.author_detail?.photo}
                        userName={article?.author_detail?.fullname}
                        sizeClass="h-12 w-12 text-lg sm:text-xl sm:h-24 sm:w-24 "
                        radius="rounded-xl"
                      />
                    </Link>
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5">
                      <span className="text-xs text-neutral-400 uppercase tracking-wider">
                        Ditulis oleh
                      </span>
                      <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                        <Link to="www.google.com">
                          {article?.author_detail?.fullname}
                        </Link>
                      </h2>
                      <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300"></span>
                    </div>
                  </div>
                  <div className="nc-SingleAuthor flex">
                    <Link to="www.google.com">
                      <Avatar
                        imgUrl={article?.editor_detail?.photo}
                        userName={article?.editor_detail?.fullname}
                        sizeClass="h-12 w-12 text-lg sm:text-xl sm:h-24 sm:w-24 "
                        radius="rounded-xl"
                      />
                    </Link>
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5">
                      <span className="text-xs text-neutral-400 uppercase tracking-wider">
                        {article.raw_file ? "Reviewed" : "Editor"}
                      </span>
                      <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                        <Link to="www.google.com">
                          {article.editor_detail.fullname}
                        </Link>
                      </h2>
                      <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="w-full mt-12 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3">
            <Sidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default PageDetailPost;
