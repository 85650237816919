import React, { FC, useState } from "react";
import ButtonCircle from "components/Button/IPIButtonCircle";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "components/NcImage/IPINcImage";
import Input from "components/Input/IPIInput";
import axios from "axios";
import { NEWSLETTER_URL } from "utils/endpoint";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {

  const [isNotifGabung, setNotifGabung] = useState(false)

  const newsletterHandle = (e: any) => {
    e.preventDefault();
    const data = new FormData();
    data.append('email', e.target.email.value)
    axios.post(NEWSLETTER_URL, data)
      .then((res) => {
        setNotifGabung(true);
        setTimeout(()=> setNotifGabung(false), 5000)
      })
      .catch((err) => {
        console.log('ERROR', err.response.data)
      })
  }

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Dapatkan Informasi Terbaru Kami</h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
          Tempat tepat untuk Ibu, belajar membangun kualitas diri dan keluarga yang bahagia
        </span>
        {isNotifGabung && <p className="mt-10 text-red-500">
          Terima kasih telah mengirimkan data Anda untuk bergabung bersama Komunitas Ibu Pembelajar.
        </p>}
        {!isNotifGabung && <form
          className="mt-10 relative max-w-sm"
          onSubmit={newsletterHandle}
        >
          <Input
            name="email"
            required
            placeholder="Tulis email"
            type="email"
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-1"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>}
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
