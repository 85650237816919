import React, { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PostCardMeta from "./PostCardMeta";
import PostFeaturedMedia from "./PostFeaturedMedia";
import { LOCALSTORAGE_USERDETAIL } from "utils/Types";
import axios from "axios";
import { Alert } from "reactstrap";
import { POST_LIKE } from "utils/endpoint";
import { LOCALSTORAGE_TOKEN } from "utils/Types";

export interface Card11Props {
  className?: string;
  post: any;
  ratio?: string;
  hiddenAuthor?: boolean;
}

const Card11: FC<Card11Props> = ({
  className = "h-full",
  post,
  hiddenAuthor = false,
  ratio = "aspect-w-4 aspect-h-4",
}) => {

  const { title} = post;

  const [isHover, setIsHover] = useState(false);

  const [loading, setLoading] = useState(false)
  const [isAlertOn, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');


  const errorHandling = (msg : any) => {
    setErrorMsg(` ${JSON.stringify(msg)}`);
    setLoading(false);
    setAlert(true);
    setTimeout(()=> {setAlert(false);}, 10000)
  };

  const [myUser, setMyUser] : any = useState(null);
  const [loadingUser, setLoadingUser] : any = useState(false);

  useEffect(() => {
    const localdata : any = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL) || '{}');
    setMyUser(localdata);
    setLoadingUser(true)
  }, []);
  const handleSubmit = (e : any) => {
  
    e.preventDefault();
    const data = new FormData();
    data.append('like_by_id', myUser.id);
    data.append('post_id',  post.post_detail.id);
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
   
    const options = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
    },
    };
    
    axios.post(POST_LIKE, data, options)
      .then((error) => {
        // const userdetail = userData.data;
        errorHandling('live data berhasil')
        // localStorage.setItem(LOCALSTORAGE_USERDETAIL, JSON.stringify(userData.data));
        window.location.reload();
        // history.push({
        //   pathname: '/dashboard/posts',
        //   // state: { data: listingAttendance },
        // });
        setLoading(false);
      })
      .catch((error) => {
        errorHandling(error.response.data)
        setLoading(false);
      }).catch((error) => {
        errorHandling(error.response.data)
      });
  }
  return (
    <div
      className={`nc-Card11 relative flex flex-col group [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="Card11"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      //
    >
      <div
        className={`block flex-shrink-0 relative w-full rounded-t-xl overflow-hidden ${ratio}`}
      >
        <div>
          <PostFeaturedMedia post={post} isHover={isHover} />
        </div>
      </div>

      <div className="p-4 flex flex-col flex-grow space-y-3">
        <PostCardMeta meta={post} />
        <h2 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100 ">
          <div  className="line-clamp-2" title={title}>
            {title}
          </div>
        </h2>
        <div className="flex items-end justify-between mt-auto">
          {/* <PostCardLikeAndComment className="relative" postData={post} /> */}
          {/* <PostCardSaveAction className="relative" postData={post} /> */}
          <div
      className={`nc-PostCardLikeAndComment flex items-center space-x-2 relative`}
      data-nc-id="PostCardLikeAndComment"
    >
      {isAlertOn && <Alert onClick={()=>setAlert(false) } color="danger">{ errorMsg}</Alert>}
      {loadingUser && myUser != null ? 
      (<form onSubmit={handleSubmit}>
        <button
        className={`nc-PostCardLikeAction relative min-w-[68px] flex items-center rounded-full leading-none group transition-colors px-3 h-8 text-xs ${
          true
            ? "text-rose-600 bg-rose-50 dark:bg-rose-100"
            : "text-neutral-700 bg-neutral-50 dark:text-neutral-200 dark:bg-neutral-800 hover:bg-rose-50 dark:hover:bg-rose-100 hover:text-rose-600 dark:hover:text-rose-500"
        }`}
          // onClick={() => onClickLike(postId)}
          title="Liked"
          type="submit"
          data-nc-id="PostCardLikeAction"
        >
          <svg
            width="24"
            height="24"
            fill={ true ? "currentColor" : "none"}
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M11.995 7.23319C10.5455 5.60999 8.12832 5.17335 6.31215 6.65972C4.49599 8.14609 4.2403 10.6312 5.66654 12.3892L11.995 18.25L18.3235 12.3892C19.7498 10.6312 19.5253 8.13046 17.6779 6.65972C15.8305 5.18899 13.4446 5.60999 11.995 7.23319Z"
              clipRule="evenodd"
            ></path>
          </svg>

          {post.post_detail.like_detail !== undefined &&
          <span
            className={`ml-1 ${
              true ? "text-rose-600" : "text-neutral-900 dark:text-neutral-200"
            }`}
          >
              Disukai {post.post_detail.like_detail.length}
          </span>
            }
          {/* {loading ? <Spinner color="success" /> : ''} */}
        </button>
      </form>)
      : 
      (
        <Link to="/login"> Login
        </Link>
      )
      }


      {/* <PostCardLikeContainer
        className={itemClass}
        like={postData.like}
        onClickLike={onClickLike}
        postId={postData.id}
      /> */}
      {/* <PostCardCommentBtn
        href={postData.href}
        commentCount={postData.commentCount}
        className={`${
          hiddenCommentOnMobile ? "hidden sm:flex" : "flex"
        }  ${itemClass}`}
      /> */}
    </div>
        </div>
      </div>
    </div>
  );
};

export default Card11;
