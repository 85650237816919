import Heading from "components/Heading/IPIHeading";
import NcImage from "components/NcImage/IPINcImage";
import NurHidayati from "./Images/nurHidayati-img.jpg"
import SitiNurArifah from "./Images/SitiNurArifah-img.jpg"
import Lim from "./Images/namalim.jpg"
import Nadira from "./Images/nadira-img.jpg"
import Tiyani from "./Images/tiyani.jpg"


const FOUNDER_DEMO: any = [
  {
    id: "1",
    name: `Siti Nur Arifah, S.P`,
    job: "Chief of Education ",
    avatar:
    SitiNurArifah,
  },
  {
    id: "2",
    name: `Nadira, S. Psi, MM`,
    job: "Chief of Riset and Development ",
    avatar:
    Nadira,
  },
  {
    id: "3",
    name: `Tiyani Rahmawati, S. Gz`,
    job: "Chief of Public Relations",
    avatar:
    Tiyani,
  },

  {
    id: "4",
    name: `Iim Choirun Nisak, S. Si`,
    job: "Chief of Social Media and Design",
    avatar:
    Lim,
  },
  {
    id: "5",
    name: `Nur Hidayati, A. Md`,
    job: "Chief of Secretariat",
    avatar:
    NurHidayati,
  },
];

const SectionPengurus = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading
        desc=""
      >
        ⛱ Pengurus IPI
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item: any) => (
          <div key={item.id} className="max-w-sm">
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover"
              src={item.avatar}
            />
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.job}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionPengurus;
