// import { TaxonomyType } from "data/types";
import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { POST_BYIDCATEGORY_URL } from "utils/endpoint";

export interface TagProps {
  className?: string;
  tag: any;
  hideCount?: boolean;
}

const Tag: FC<TagProps> = ({ className = "", tag, hideCount = false }) => {
  const [countOfTag, setCountOfTag] : any = useState(0);

  useEffect(() => {
    axios.get(`${POST_BYIDCATEGORY_URL + tag.id}/`)
      .then((res) => {
        setCountOfTag(res.data.length)
      }).catch((error) => {
        console.log(error.response.data)
      });

  })

  return (
    <Link
      className={`nc-Tag inline-block bg-white text-sm text-neutral-600 py-2 px-3 rounded-lg border border-neutral-100 md:py-2.5 md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 ${className}`}
      data-nc-id="Tag"
      to={tag.href}
    >
      {`${tag.name}`}
      {!hideCount && (
        <span className="text-xs font-normal"> ({countOfTag})</span>
        // <span className="text-xs font-normal"> ({tag.count})</span>
      )}
    </Link>
  );
};

export default Tag;
