import React, { FC, useState, useEffect } from "react";
import LayoutPage from "components/LayoutPage/IPILayoutPage";
import Textarea from "components/Textarea/Textarea";
import Input from "components/Input/IPIInput";
import ButtonPrimary from "components/Button/IPIButtonPrimary";
import NcLink from "components/NcLink/IPINcLink";
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import { AUTH_URL_SIGNUP, AUTH_URL_LOGIN, AUTH_URL_USERME } from "../../utils/endpoint";
import {
  Alert } from 'reactstrap'
  import {
    LOCALSTORAGE_TOKEN,
    LOCALSTORAGE_USERDETAIL,
  } from '../../utils/Types';
export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const history: any = useHistory();

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  const [isAlertOn, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setLoading] = useState(false);
  
  // const params: any = useParams();
  const errorHandling = (msg: any) => {
    setErrorMsg(` ${JSON.stringify(msg)}`);
    setLoading(false);
    setAlert(true);
    setTimeout(() => { setAlert(false); }, 5000)
  };
  const signUpHandle = (e: any) => {
    e.preventDefault();
    if(e.target.password1.value !== e.target.password2.value){
      errorHandling("Konfirmasi password tidak sama. Silahkan cek kembali");
      return;
    }

    const data = new FormData();
    data.append('fullname', e.target.fullname.value);
    data.append('email', e.target.email.value);
    data.append('phone', e.target.phone.value);
    data.append('address', e.target.address.value);
    data.append('password', e.target.password1.value);
    setLoading(true);
    axios.post(AUTH_URL_SIGNUP, data)
      .then((res) => {
        setLoading(false);
        const data = new FormData();
        data.append('email', e.target.email.value);
        data.append('password', e.target.password1.value);
        setLoading(true);
        axios.post(AUTH_URL_LOGIN, data)
          .then((res) => {
            // authentication
            const token = res.data.auth_token;
            if (token) {
              localStorage.setItem(LOCALSTORAGE_TOKEN, token);
              // authorization
              axios.get(AUTH_URL_USERME, { headers: { Authorization: `Token ${token}` } }).then((userData: any) => {
                const userdetail = userData.data;
                setLoading(false);
                // if (userdetail.status === 'ADMIN') {
                  localStorage.setItem(LOCALSTORAGE_USERDETAIL, JSON.stringify(userData.data));
                if(history.location.state){
                  history.push(history.location.state)
                  window.location.reload()
                }else{
                  history.push('/');
                  window.location.reload()
                }
              })
            }
          }).catch((error) => {
            errorHandling(error.response.data);
          });
      }).catch((error) => {
        errorHandling(error.response.data);
      });
  }
  return (
    <div className={`nc-PageSignUp ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Pendaftaran Akun</title>
      </Helmet>
      <LayoutPage
        subHeading="Selamat datang di Komunitas Ibu Pembelajar Indonesia"
        headingEmoji="🎉"
        heading="Daftar"
      >
        <div className="max-w-md mx-auto space-y-6">
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-neutral-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              atau
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={signUpHandle}>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Nama Lengkap
              </span>
              <Input
                name="fullname"
                type="text"
                placeholder="Masukan nama lengkap anda"
                className="mt-1"
                required
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Alamat Email
              </span>
              <Input
                name="email"
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                required
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Nomor Whatsapp (Misal: 62812xxxxx)
              </span>
              <Input
                name="phone"
                type="number"
                placeholder="62812xxxxx"
                className="mt-1"
                required
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Alamat
              </span>
              <Textarea
                name="address"
                className="mt-1"
                placeholder="Masukan alamat rumah Anda"
                rows={6}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Kata Sandi
              </span>
              <Input
                name="password1"
                type="password"
                className="mt-1"
                placeholder="Masukan kata sandi"
                required
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Ulangi Kata Sandi
              </span>
              <Input
                name="password2"
                type="password"
                className="mt-1"
                placeholder="Ulangi kata sandi"
                required
              />
            </label>
            {isAlertOn && <Alert onClick={() => setAlert(false)} type="danger">{errorMsg}</Alert>}
            <div className="flex items-center justify-center">
              {isLoading && <div>loading...</div>}
            </div>
            <ButtonPrimary type="submit">Daftar</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Sudah punya akun? {` `}
            {/* <NcLink to="/login">Masuk</NcLink> */}
            {history.location.state ? (
              <>
                <NcLink to={{
                  pathname: '/login',
                  state: history.location.state 
                }}>Masuk</NcLink>
              </>
            ) : (
              <NcLink to="/login">Masuk</NcLink>
            )}
          </span>
        </div>
      </LayoutPage>
    </div>
  );
};

export default PageSignUp;
