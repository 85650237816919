import ButtonPrimary from "components/Button/IPIButtonPrimary";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const PageSuccess: React.FC = () => {
  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-color-ipi";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);
  return (
    <div className="nc-Page404">
      <Helmet>
        <title>Pembayaran Berhasil</title>
      </Helmet>
      <div className="container relative py-16 lg:py-20">
        {/* HEADER */}
        <header className="text-center max-w-2xl mx-auto space-y-7">
          <h2 className="text-7xl md:text-8xl">🪔</h2>
          <h1 className="text-8xl md:text-9xl font-semibold tracking-widest">
            Selamat
          </h1>
          <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
            Pembayaran Berhasil{" "}
          </span>
          <ButtonPrimary href="/dashboard/root" className="mt-4">
            Dashboard
          </ButtonPrimary>
        </header>
      </div>
    </div>
  );
};

export default PageSuccess;
