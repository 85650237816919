import React, {useEffect, useState} from "react";
import NcImage from "components/NcImage/IPINcImage";
import Pagination from "components/Pagination/Pagination";
import axios from "axios";
import { LOCALSTORAGE_USERDETAIL, LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import ButtonDelete from "components/Button/ButtonDelete";
import { useHistory } from "react-router-dom";

const DetailHandler = (history: any, info: any) => {
  // console.log("send data", info.id);
  history.push({
    pathname:`/my-submission/submission/${info.id}`,
    state: { data: info }});
};

const DashboardCompetitions = (stateOri:any) => {
  const history = useHistory()
  const { location } = stateOri;
  const { state } = location;
  const { data } = state;
  // console.log("state", data);
  const [posts, setPost]: any = useState([]);
  const [loadingPost, setLoadingPost]: any = useState(false);

  useEffect(() => {
    const localdata : any = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL) || '{}');
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    // console.log('TOKEN', token)
    const options = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
    },
    };
    axios
      .get(`https://api.ibupembelajar.id/api-ipi/dashboard-submissionbyidcompetition/${data.competition_id}/iduser/${localdata.id}/`, options)
      .then((res : any) => {
        setPost(res.data);
        setLoadingPost(true);
      })
      .catch((error : any) => {
        console.log(error.response.data);
      });
  }, []);
  // console.log("state", posts);

  return (
    <div className="flex flex-col space-y-8">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full px-1 sm:px-6 lg:px-8">
          <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
            <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
              <thead className="bg-neutral-50 dark:bg-neutral-800">
                <tr className="text-left text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
                  <th scope="col" className="px-6 py-3">
                    Data
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Submitted
                  </th>
                  {/* <th scope="col" className="px-6 py-3">
                   Tutup
                  </th>

                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th> */}
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
                {loadingPost && posts.map((item : any) => (
                  <tr key={item.id}>
                    <td className="px-6 py-4">
                      <div className="flex items-center w-96 lg:w-auto max-w-md overflow-hidden">
                        <NcImage
                          containerClassName="flex-shrink-0 h-12 w-12 rounded-lg overflow-hidden lg:h-14 lg:w-14"
                          src={item.competition_detail.image}
                        />
                        <div className="ml-4 flex-grow">
                          <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                            {item.post_detail.title}
                          </h2>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-teal-100 text-teal-900 lg:text-sm">
                          {item.post_detail.created_at}
                        </span>
                    </td>
                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-neutral-500 dark:text-neutral-400">
                      {item.competition_detail.end_date}
                    </td> */}
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-neutral-300">
                      {/* <ButtonEdit onClick={() => UnggahHandler(history, item)}>
                        Unggah
                      </ButtonEdit> */}
                      {/* {` | `} */}
                      <ButtonDelete onClick={() => DetailHandler(history, item)}>
                        Detail
                      </ButtonDelete>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Pagination />
    </div>
  );
};

export default DashboardCompetitions;
