import React, { FC } from "react";
import Heading from "components/Heading/IPIHeading";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import ButtonSecondary from "components/Button/ButtonSecondary";
import { useHistory } from "react-router-dom";

export interface HeaderFilterProps {
  tabActive: any;
  tabs: any[];
  heading: any;
  onClickTab: (item: any) => void;

}const DetailHandler = (history: any) => {
  history.push({
    pathname:`/articles`});
};

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive,
  tabs,
  heading = "🎈 Latest Articles",
  onClickTab,
}) => {
  const history = useHistory();
  return (
    <div className="flex flex-col mb-8 relative">
      <Heading>{heading}</Heading>
      <div className="flex items-center justify-between">
        <Nav
          className="sm:space-x-2"
          containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base"
        >
          {tabs.map((data) => (
            <NavItem
              key={data.id}
              isActive={tabActive === data.name}
              onClick={() => onClickTab(data.name)}
            >
              {data.name}
            </NavItem>
          ))}
        </Nav>
        <span className="hidden sm:block flex-shrink-0">
          <ButtonSecondary
            onClick={() => DetailHandler(history)}
            className="!leading-none"
          >
            <span>Lihat Semua</span>
            <i className="ml-3 las la-arrow-right text-xl"></i>
          </ButtonSecondary>
        </span>
      </div>
    </div>
  );
};

export default HeaderFilter;
